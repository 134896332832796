
import Vue from "vue";

interface Computed {
  help: string;
}

export default Vue.extend<unknown, unknown, Computed, unknown>({
  computed: {
    help() {
      return process.env.VUE_APP_NOTION_HELP!;
    },
  },
});
