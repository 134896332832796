
import Vue from "vue";
import CupertinoAlertDialog from "@/components/ui/dialogs/CupertinoAlertDialog.vue";

interface Props {
  title: string;
  close?: string;
  submit?: string;
  submitHandler?: (value: string) => void;
}

interface Data {
  value: string;
}

interface Computed {}

interface Methods {
  onClickOutSide: () => void;
  onClickClose: () => void;
  onClickSubmit: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CupertinoAlertDialog },

  props: {
    title: String,
    close: {
      type: String,
      default: "閉じる",
      required: false,
    },
    submit: {
      type: String,
      default: "送信",
      required: false,
    },
    submitHandler: {
      type: Function,
      required: false,
    },
  },

  data() {
    return {
      value: "",
    };
  },

  methods: {
    onClickOutSide() {
      this.$close(false);
    },
    onClickClose() {
      this.$close(false);
    },
    onClickSubmit() {
      if (this.submitHandler) this.submitHandler(this.value);
      this.$close(true);
    },
  },
});

export type InputDialogProps = Props;
