
import Vue, { PropType } from "vue";
import { format, getTime } from "date-fns";
import StarOutlineIcon from "icons/StarOutline.vue";
import EyeOutlineIcon from "icons/EyeOutline.vue";
import BookmarkOutlineIcon from "icons/BookmarkOutline.vue";
import CommentTextOutlineIcon from "icons/CommentTextOutline.vue";
import OpenInNewIcon from "icons/OpenInNew.vue";
import NolaNovelListItem from "@/components/molecules/NolaNovelListItem.vue";
import { AnalyticsItem, Novel, NovelFromNolaNovel } from "@/lib/models";
import { NolaNovelUrlGenerator } from "@/lib/utils/generator/nolanovelUrl";
import { createUrlWithUtmParams } from "@/lib/utils/url";
import { NolaItemId, NolaPageName } from "@/lib/utils/analytics";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    StarOutlineIcon,
    EyeOutlineIcon,
    BookmarkOutlineIcon,
    CommentTextOutlineIcon,
    OpenInNewIcon,
    NolaNovelListItem,
  },
  props: {
    novel: Object as PropType<Novel>,
    novelFromNolaNovel: Object as PropType<NovelFromNolaNovel>,
  },
  computed: {
    novelIdFromNolaNovel() {
      const { associatedData } = this.novel;
      if (associatedData) return associatedData.nolaNovel.id;
      return undefined;
    },
    title() {
      return this.novel.title;
    },
    updatedAt() {
      const { episodes, updatedAt } = this.novelFromNolaNovel;

      if (!episodes || episodes.length === 0) return format(updatedAt, "YYYY/MM/DD");

      const publishedAts = episodes
        .map((e) => e.publishedAt ?? 0)
        .filter((p: number) => p > 0 && p < getTime(new Date()));

      if (publishedAts.length === 0) {
        return format(updatedAt, "YYYY/MM/DD");
      }
      const publishedAt = Math.max.apply(null, publishedAts);
      return format(publishedAt, "YYYY/MM/DD");
    },
    publishedAt() {
      const { episodes } = this.novelFromNolaNovel;

      if (!episodes || episodes.length === 0) return "-";

      const publishedAts = episodes
        .map((e) => e.publishedAt ?? 0)
        .filter((p: number) => p > 0 && p < getTime(new Date()));

      if (publishedAts.length === 0) {
        return "-";
      }
      const publishedAt = Math.min.apply(null, publishedAts);
      return format(publishedAt, "YYYY/MM/DD");
    },
    episodeCount() {
      const { episodes } = this.novelFromNolaNovel;

      if (!episodes) return 0;

      return episodes.length;
    },
    characterCount() {
      const { episodes } = this.novelFromNolaNovel;

      if (!episodes) return 0;

      return episodes.reduce((acc, cur) => acc + cur.characterCount, 0);
    },
    review() {
      const review = this.$store.getters["nolaNovelModule/analyticsById"]("REVIEW#ALL", this.novelIdFromNolaNovel) as
        | AnalyticsItem
        | undefined;
      return review ? review.count : 0;
    },
    pv() {
      const review = this.$store.getters["nolaNovelModule/analyticsById"](
        "PV#EPISODE#ALL",
        this.novelIdFromNolaNovel
      ) as AnalyticsItem | undefined;
      return review ? review.count : 0;
    },
    bookmark() {
      const review = this.$store.getters["nolaNovelModule/analyticsById"]("BOOKMARK#ALL", this.novelIdFromNolaNovel) as
        | AnalyticsItem
        | undefined;
      return review ? review.count : 0;
    },
    comment() {
      const review = this.$store.getters["nolaNovelModule/analyticsById"]("COMMENT#ALL", this.novelIdFromNolaNovel) as
        | AnalyticsItem
        | undefined;
      return review ? review.count : 0;
    },
  },
  methods: {
    async onClickCreateEpisode() {
      const url = process.env.VUE_APP_NOLANOVEL_WEB;

      if (!url) {
        throw new Error("NolaノベルのWebサイトURLが環境変数に含まれていません。");
      }

      const service = new NolaNovelUrlGenerator(process.env.VUE_APP_NOLANOVEL_WEB!);
      const postEpisodeUrl = await service.genPostEpisodeUrl(this.novelIdFromNolaNovel!);
      window.open(
        createUrlWithUtmParams(postEpisodeUrl, NolaPageName.NolaNovel, NolaItemId.PostEpisodeButton),
        process.env.VUE_APP_NOLANOVEL_NAME
      );
    },
    async onClickUpdateNovel() {
      const url = process.env.VUE_APP_NOLANOVEL_WEB;

      if (!url) {
        throw new Error("NolaノベルのWebサイトURLが環境変数に含まれていません。");
      }

      const { novelIdFromNolaNovel, novel } = this;

      const service = new NolaNovelUrlGenerator(process.env.VUE_APP_NOLANOVEL_WEB!);
      const updateNovelUrl = await service.genUpdateNovelUrl(novelIdFromNolaNovel!, novel.novelId);
      window.open(
        createUrlWithUtmParams(updateNovelUrl, NolaPageName.NolaNovel, NolaItemId.UpdateNovelButton),
        process.env.VUE_APP_NOLANOVEL_NAME
      );
    },
    async onClickTransNovel() {
      const url = process.env.VUE_APP_NOLANOVEL_WEB;

      if (!url) {
        throw new Error("NolaノベルのWebサイトURLが環境変数に含まれていません。");
      }

      const service = new NolaNovelUrlGenerator(process.env.VUE_APP_NOLANOVEL_WEB!);
      const novelUrl = await service.genMyPageNovelUrl(this.novelIdFromNolaNovel!);
      window.open(
        createUrlWithUtmParams(novelUrl, NolaPageName.NolaNovel, NolaItemId.NavigateToPublishedPageButton),
        process.env.VUE_APP_NOLANOVEL_NAME
      );
    },
  },
});

interface Props {
  novel: Novel;
  novelFromNolaNovel: NovelFromNolaNovel;
}

interface Data {}

interface Computed {
  novelIdFromNolaNovel?: string;
  title: string;
  updatedAt: string;
  publishedAt: string;
  episodeCount: number;
  characterCount: number;
  review: number;
  pv: number;
  bookmark: number;
  comment: number;
}

interface Methods {
  onClickCreateEpisode(): void;
  onClickUpdateNovel(): void;
  onClickTransNovel(): void;
}
