
import Vue from "vue";
import { VueLoading } from "vue-loading-template";
import EventHeader from "@/components/organisms/EventHeader.vue";
import CompanyCard from "@/components/molecules/CompanyCard.vue";
import axiosBase from "axios";
import { showNotifyDialog } from "@/lib/dialog";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_NOLANOVEL_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_NOLANOVEL_MICROCMS_API_KEY },
});

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { VueLoading, EventHeader, CompanyCard },
  data() {
    return {
      isLoading: true,
      companies: [],
    };
  },
  async created() {
    const companyRequestUrl = "/company?limit=100";
    const company = await axios.get(companyRequestUrl);

    if (company.status !== 200) {
      await showNotifyDialog({
        title: "エラー",
        content: "コンテスト情報の取得に失敗しました。",
      });
    }

    this.companies = company.data.contents;
    this.isLoading = false;
  },
});

interface Props {}

interface Data {
  isLoading: boolean;
  companies: Company[];
}

interface Computed {}

interface Methods {}

interface Company {
  id: string;
}
