
import Vue, { PropType } from "vue";
import { NolaContest } from "@/lib/models/nolaContest";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    contest: Object as PropType<NolaContest>,
  },
});

interface Props {
  contest: NolaContest;
}

interface Data {}

interface Computed {}

interface Methods {}
