
import Vue from "vue";
import InfiniteLoading, { StateChanger } from "vue-infinite-loading";
import { NotificationDataType, NotificationFromNolaNovel, NotificationsFromNolaNovel } from "@/lib/models";
import NolaNovelNotificationListItem from "@/components/molecules/NolaNovelNotificationListItem.vue";

const Tabs = {
  all: "all",
  reader: "reader",
  editor: "editor",
  another: "another",
} as const;
type TabType = typeof Tabs[keyof typeof Tabs];

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { InfiniteLoading, NolaNovelNotificationListItem },
  async created() {
    const unreadNotifications = this.$store.getters["nolaNovelModule/notifications"](
      false
    ) as NotificationsFromNolaNovel;
    if (unreadNotifications.items.length === 0)
      this.$store.dispatch("nolaNovelModule/fetchNotifications", { isRead: false });
  },
  data() {
    return {
      tabs: Tabs,
      currentTab: "all",
      timeout: undefined,
      isCompleteUnread: false,
      isCompleteFirstRead: false,
      isCompleteRead: false,
    };
  },
  computed: {
    isActiveTab() {
      return (name) => this.currentTab === name;
    },
    isNoData() {
      return (
        this.isCompleteUnread &&
        this.isCompleteRead &&
        this.filteredUnreadNotifications.length === 0 &&
        this.filteredReadNotifications.length === 0
      );
    },
    unreadNotifications() {
      return this.$store.getters["nolaNovelModule/notifications"](false);
    },
    readNotifications() {
      return this.$store.getters["nolaNovelModule/notifications"](true);
    },
    filteredUnreadNotifications() {
      switch (this.currentTab) {
        case Tabs.all:
        default:
          return this.unreadNotifications.items;
        case Tabs.reader:
          return this.unreadNotifications.items.filter(
            (item) =>
              item.type === NotificationDataType.REVIEW ||
              item.type === NotificationDataType.BOOKMARK ||
              item.type === NotificationDataType.FOLLOW ||
              item.type === NotificationDataType.COMMENT ||
              item.type === NotificationDataType.COMMENT_REPLY ||
              item.type === NotificationDataType.COMMENT_ACTIVITY ||
              item.type === NotificationDataType.COMMENT_ACTIVITY_REPLY ||
              item.type === NotificationDataType.FAVORITE_ACTIVITY
          );
        case Tabs.editor:
          return this.unreadNotifications.items.filter(
            (item) =>
              item.type === NotificationDataType.COMPANY_FOOTPRINTS ||
              item.type === NotificationDataType.COMPANY_BOOKMARK
          );
        case Tabs.another:
          return this.unreadNotifications.items.filter(
            (item) =>
              item.type === NotificationDataType.NOVEL_UPDATED ||
              item.type === NotificationDataType.FOLLOW_NEWNOVEL ||
              item.type === NotificationDataType.FOLLOW_NEWEPISODE ||
              item.type === NotificationDataType.FOLLOW_NEWACTIVITY
          );
      }
    },
    filteredReadNotifications() {
      switch (this.currentTab) {
        case Tabs.all:
        default:
          return this.readNotifications.items;
        case Tabs.reader:
          return this.readNotifications.items.filter(
            (item) =>
              item.type === NotificationDataType.REVIEW ||
              item.type === NotificationDataType.BOOKMARK ||
              item.type === NotificationDataType.FOLLOW ||
              item.type === NotificationDataType.COMMENT ||
              item.type === NotificationDataType.COMMENT_REPLY
          );
        case Tabs.editor:
          return this.readNotifications.items.filter(
            (item) =>
              item.type === NotificationDataType.COMPANY_FOOTPRINTS ||
              item.type === NotificationDataType.COMPANY_BOOKMARK
          );
        case Tabs.another:
          return this.readNotifications.items.filter(
            (item) =>
              item.type === NotificationDataType.NOVEL_UPDATED ||
              item.type === NotificationDataType.FOLLOW_NEWNOVEL ||
              item.type === NotificationDataType.FOLLOW_NEWEPISODE
          );
      }
    },
  },
  methods: {
    onClickTab(name) {
      this.currentTab = name;
    },
    async infiniteUnreadHandler($state: StateChanger) {
      if (this.unreadNotifications.lastKey) {
        await this.$store.dispatch("nolaNovelModule/loadNotifications", { isRead: false });
        $state.loaded();
      } else {
        // 未読を取得し切ったら既読通知を取得する
        $state.complete();
        this.isCompleteUnread = true;

        // 3秒後に全通知を既読にする
        this.timeout = setTimeout(this.readAllNotifications, 3000);
      }
    },
    async infiniteReadHandler($state: StateChanger) {
      if (!this.isCompleteFirstRead) {
        await this.$store.dispatch("nolaNovelModule/fetchNotifications", { isRead: true });
        this.isCompleteFirstRead = true;
        $state.loaded();
      } else if (this.readNotifications.lastKey) {
        await this.$store.dispatch("nolaNovelModule/loadNotifications", { isRead: true });
        $state.loaded();
      } else {
        $state.complete();
        this.isCompleteRead = true;
      }
    },
    readAllNotifications() {
      if (this.unreadNotifications.items.length > 0) {
        this.$store.dispatch("nolaNovelModule/readAllNotifications");
      }
    },
  },
});

interface Props {}

interface Data {
  tabs: any;
  currentTab: TabType;
  timeout: any;
  isCompleteUnread: boolean;
  isCompleteFirstRead: boolean;
  isCompleteRead: boolean;
}

interface Computed {
  isActiveTab(name: TabType): boolean;
  isNoData: boolean;
  unreadNotifications: NotificationsFromNolaNovel;
  readNotifications: NotificationsFromNolaNovel;
  filteredUnreadNotifications: NotificationFromNolaNovel[];
  filteredReadNotifications: NotificationFromNolaNovel[];
}

interface Methods {
  onClickTab(name: TabType): void;
  infiniteUnreadHandler($state: StateChanger): void;
  infiniteReadHandler($state: StateChanger): void;
  readAllNotifications(): void;
}
