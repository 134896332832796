
import Vue from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    fill: {
      type: String,
      default: "#474A4D",
    },
  },
});

interface Props {
  fill: string;
}

interface Data {}

interface Computed {}

interface Methods {}
