
import Vue from "vue";
import { VueLoading } from "vue-loading-template";
import EventHeader from "@/components/organisms/EventHeader.vue";
import ContestCard from "@/components/molecules/ContestCard.vue";
import axiosBase from "axios";
import { showNotifyDialog } from "@/lib/dialog";
import { compareAsc, compareDesc, format } from "date-fns";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_MICROCMS_API_KEY },
});

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { VueLoading, EventHeader, ContestCard },
  data() {
    return {
      isLoading: true,
      contests: [],
    };
  },
  async created() {
    const contestRequestUrl = "/contest?limit=1000";
    const contestRequest = axios.get(contestRequestUrl);

    const result = await Promise.all([contestRequest]);

    const contest = result.find((x) => x.config.url === contestRequestUrl)!;

    if (contest.status !== 200) {
      await showNotifyDialog({
        title: "エラー",
        content: "コンテスト情報の取得に失敗しました。",
      });
    }

    this.contests = contest.data.contents;
    this.isLoading = false;
  },
  computed: {
    availableContests() {
      const { contests } = this;
      const currentDate = format(new Date(), "YYYY-MM-DD");
      const availableContests = contests.filter((contest) => {
        const contestDeadline = format(contest.deadline, "YYYY-MM-DD");
        return compareAsc(currentDate, contestDeadline) !== 1;
      });

      return availableContests.sort((a, b) => {
        const aDeadline = format(a.deadline, "YYYY-MM-DD");
        const bDeadline = format(b.deadline, "YYYY-MM-DD");

        return compareDesc(aDeadline, bDeadline);
      });
    },
    closedContests() {
      const { contests } = this;
      const currentDate = format(new Date(), "YYYY-MM-DD");
      const closedContests = contests.filter((contest) => {
        const contestDeadline = format(contest.deadline, "YYYY-MM-DD");
        return compareAsc(currentDate, contestDeadline) === 1;
      });

      return closedContests.sort((a, b) => {
        const aDeadline = format(a.deadline, "YYYY-MM-DD");
        const bDeadline = format(b.deadline, "YYYY-MM-DD");

        return compareDesc(aDeadline, bDeadline);
      });
    },
    sortedContests() {
      const { availableContests, closedContests } = this;
      return [...availableContests, ...closedContests];
    },
  },
});

interface Props {}

interface Data {
  isLoading: boolean;
  contests: Contest[];
}

interface Computed {
  availableContests: Contest[];
  closedContests: Contest[];
  sortedContests: Contest[];
}

interface Methods {}

type Contest = {
  [key: string]: any;
};
