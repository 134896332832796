
/* eslint-disable no-param-reassign */
import Vue from "vue";

interface Methods {
  enter: (el: HTMLElement) => void;
  leave: (el: HTMLElement) => void;
  afterEnter: (el: HTMLElement) => void;
  afterLeave: (el: HTMLElement) => void;
  nextFrame: (fn: any) => void;
}

interface Props {
  isOpen: boolean;
}

export default Vue.extend<unknown, Methods, unknown, Props>({
  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    enter(el) {
      el.style.overflow = "hidden";
      el.style.height = "0";

      this.nextFrame(() => {
        el.style.height = `${el.scrollHeight}px`;
      });
    },
    leave(el) {
      el.style.overflow = "hidden";
      el.style.height = `${el.scrollHeight}px`;

      this.nextFrame(() => {
        el.style.height = "0";
      });
    },
    afterEnter(el) {
      el.style.height = "";
      el.style.overflow = "";
    },
    afterLeave(el) {
      el.style.height = "";
      el.style.overflow = "";
    },
    nextFrame(fn) {
      // NOTE: 以下参考にした
      // https://note.com/noliaki/n/n6e3a60748c11
      window.requestAnimationFrame(() => window.requestAnimationFrame(fn));
    },
  },
});
