import { z } from "zod";
import { ContestEntryItemModel } from "@/lib/models/nolaContest";

export function generateEntryItemSchema(item: ContestEntryItemModel) {
  let schema: z.ZodType;
  switch (item.type) {
    case "string":
    case "title":
    case "synopsis": {
      let stringSchema = z.string();

      if (item.min != null) {
        stringSchema = stringSchema.min(item.min);
      }
      if (item.max != null) {
        stringSchema = stringSchema.max(item.max, `${item.name}は${item.max}文字以内で指定してください。`);
      }
      if (item.regex != null) {
        stringSchema = stringSchema.regex(new RegExp(item.regex), `${item.name}の形式が正しくありません。`);
      }

      schema = stringSchema;
      break;
    }
    case "number": {
      let numberSchema = z.number();

      if (item.isInt) {
        numberSchema = numberSchema.int(`整数で指定してください。`);
      }
      if (item.min != null) {
        numberSchema = numberSchema.min(item.min, `${item.name}は${item.min}以上で指定してください。`);
      }
      if (item.max != null) {
        numberSchema = numberSchema.max(item.max, `${item.name}は${item.max}以下で指定してください。`);
      }

      schema = numberSchema;
      break;
    }
    case "select": {
      const selectSchema = z.enum(item.options as [string, ...string[]]);

      schema = selectSchema;
      break;
    }
    case "file": {
      const acceptExtensionsMap = {
        excel: [".xls", "xlsx"],
        csv: [".csv", ".tsv"],
        word: [".doc", ".docx"],
        pdf: [".pdf"],
      };
      const extensions = item.formats.map((f) => acceptExtensionsMap[f]).flat();

      const fileSchema = z.object({
        size: z
          .number()
          .max(item.maxSize, `ファイルサイズは${Math.floor(item.maxSize / (1024 * 1024))}MB以内にする必要があります。`),
        name: z
          .string()
          .refine(
            (s) => extensions.some((ext) => s.endsWith(ext)),
            `このファイル形式(拡張子)は使用できません。使用可能な形式は ${extensions.join("、")} です。`
          ),
      });

      schema = fileSchema;
      break;
    }
    case "manuscripts": {
      const manuscriptSchema = z.array(z.string());

      schema = manuscriptSchema;
      break;
    }
    case "characters": {
      let characterSchema = z.array(z.string());

      if (item.min)
        characterSchema = characterSchema.min(item.min, `${item.name}は${item.min}個以上を選択してください。`);

      if (item.max)
        characterSchema = characterSchema.max(item.max, `${item.name}は${item.max}個以下を選択してください。`);

      schema = characterSchema;
      break;
    }
    case "plot": {
      const plotSchema = z.string();

      schema = plotSchema;
      break;
    }
    case "worldviews":
    case "correlations":
      schema = z.any();
      break;
    default:
      console.warn(`invalid item type: ${JSON.stringify(item)}`);
      throw new Error(`invalid item type: ${JSON.stringify(item)}`);
  }

  if (item.optional) schema = schema.nullish();

  return schema;
}

const REQUIRED_ERROR_MESSAGE = "この項目は必須です。";

const errorMap: z.ZodErrorMap = (error, ctx) => {
  switch (error.code) {
    case z.ZodIssueCode.invalid_enum_value:
      return {
        message: "選択してください。",
      };
    case z.ZodIssueCode.invalid_arguments:
      return {
        message: "入力値が不正です。",
      };
    case z.ZodIssueCode.too_small:
      if (error.minimum === 1 && error.type === "string") {
        return {
          message: REQUIRED_ERROR_MESSAGE,
        };
      }
      if (ctx.data == null) {
        return {
          message: REQUIRED_ERROR_MESSAGE,
        };
      }
      return {
        message: `${error.minimum.toLocaleString()}以上で指定してください。`,
      };
    case z.ZodIssueCode.too_big:
      return {
        message: `${error.maximum.toLocaleString()}以下で指定してください。`,
      };
    case z.ZodIssueCode.invalid_type:
      if (error.expected === "object" && error.received === "null") {
        return {
          message: REQUIRED_ERROR_MESSAGE,
        };
      }
      return {
        message: "入力値が不正です。",
      };
    case z.ZodIssueCode.invalid_string:
      if (ctx.data === undefined) {
        return {
          message: REQUIRED_ERROR_MESSAGE,
        };
      }
      return {
        message: "入力した文字列は不正な形式です。",
      };
    case z.ZodIssueCode.invalid_union:
      return {
        message: "入力値が不正です。",
      };
    default:
      break;
  }

  return { message: ctx.defaultError };
};

z.setErrorMap(errorMap);
