
import Vue, { PropType } from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    value: {
      type: String as PropType<string>,
      default: "",
    },
    initialValue: String as PropType<string>,
    type: {
      type: String as PropType<string>,
      default: "text",
    },
    placeholder: String as PropType<string>,
    min: Number as PropType<number>,
    max: Number as PropType<number>,
    minlength: Number as PropType<number>,
    maxlength: Number as PropType<number>,
    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    rows: {
      type: Number as PropType<number>,
      default: 0,
    },
    isDisplayCount: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    error: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  created() {
    if (!this.value && this.initialValue) {
      this.$emit("input", this.initialValue);
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value: string) {
        this.$emit("input", value);
      },
    },
  },
});

interface Props {
  value: string;
  initialValue?: string;
  type?: string;
  placeholder: string;
  min?: number;
  max?: number;
  minlength?: number;
  maxlength?: number;
  readonly?: boolean;
  rows?: number;
  isDisplayCount?: boolean;
  error: boolean;
}

interface Data {}

interface Computed {
  internalValue: string;
}

interface Methods {}
