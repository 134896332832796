
import Vue from "vue";

interface Data {
  scrollY: number;
}

interface Computed {
  help: string;
}

interface Methods {
  scroll(): void;
}

export default Vue.extend<Data, Methods, Computed, unknown>({
  data() {
    return {
      scrollY: 0,
    };
  },
  created() {
    window.addEventListener("scroll", this.scroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scroll);
  },
  computed: {
    help() {
      return process.env.VUE_APP_NOTION_HELP!;
    },
  },
  methods: {
    scroll() {
      this.scrollY = window.scrollY;
    },
  },
});
