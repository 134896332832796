
import Vue from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  created() {
    this.$store.dispatch("writingDataModule/initialize");
  },
  computed: {
    todayWritingCount() {
      return this.$store.getters["writingDataModule/todayWritingCount"];
    },
    last7DaysWritingCount() {
      return this.$store.getters["writingDataModule/last7DaysWritingCount"];
    },
  },
});

interface Props {}

interface Data {}

interface Computed {
  todayWritingCount: number;
  last7DaysWritingCount: number;
}

interface Methods {}
