
import Vue from "vue";
import { VueLoading } from "vue-loading-template";
import NolaNovelAssociatedNovelListItem from "@/components/molecules/NolaNovelAssociatedNovelListItem.vue";
import NolaNovelNonAssociatedNovelListItem from "@/components/molecules/NolaNovelNonAssociatedNovelListItem.vue";
import NolaNovelEmptyList from "@/components/molecules/NolaNovelEmptyList.vue";
import { AnalyticsDataType, AnalyticsTerm, ConnectedOtherServices, Novel, NovelFromNolaNovel } from "@/lib/models";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { VueLoading, NolaNovelAssociatedNovelListItem, NolaNovelNonAssociatedNovelListItem, NolaNovelEmptyList },
  async created() {
    try {
      this.isLoading = true;

      const novelInitialize = this.$store.dispatch("novelModule/initialize");
      const userInitialize = this.$store.dispatch("userModule/initialize");
      await Promise.all([novelInitialize, userInitialize]);

      const connectedOtherServices: ConnectedOtherServices = this.$store.getters["userModule/connectedOtherServices"];
      if (!connectedOtherServices.nolaNovel.result) return;

      const novelsFromNolaNovel: NovelFromNolaNovel[] = await this.$store.dispatch("nolaNovelModule/fetchNovels");
      if (novelsFromNolaNovel.length === 0) return;

      await this.$store.dispatch(
        "nolaNovelModule/fetchAnalytics",
        novelsFromNolaNovel.flatMap((value) => [
          {
            targetId: value.id,
            type: AnalyticsDataType.PV_EPISODE,
            term: AnalyticsTerm.ALL,
          },
          {
            targetId: value.id,
            type: AnalyticsDataType.REVIEW,
            term: AnalyticsTerm.ALL,
          },
          {
            targetId: value.id,
            type: AnalyticsDataType.BOOKMARK,
            term: AnalyticsTerm.ALL,
          },
          {
            targetId: value.id,
            type: AnalyticsDataType.COMMENT,
            term: AnalyticsTerm.ALL,
          },
        ])
      );
    } finally {
      this.isLoading = false;
    }
  },
  mounted() {
    if (this.associatedNovels.length > 0) this.isModeAssociatedNovels = true;
  },
  data() {
    return {
      isLoading: true,
      isModeAssociatedNovels: false,
    };
  },
  computed: {
    novels() {
      return this.$store.getters["novelModule/novels"];
    },
    novelsFromNolaNovel() {
      return this.$store.getters["nolaNovelModule/novels"];
    },
    novelFromNolaNovel() {
      return (novel) => {
        const { id } = novel.associatedData!.nolaNovel;
        return this.novelsFromNolaNovel.find((value) => value.id === id);
      };
    },
    associatedNovels() {
      const { novels, novelFromNolaNovel } = this;
      return novels.filter((novel) => novel.associatedData && novelFromNolaNovel(novel));
    },
    nonAssociatedNovels() {
      const { novels, novelFromNolaNovel } = this;
      return novels.filter((novel) => !novel.associatedData || !novelFromNolaNovel(novel));
    },
  },
  methods: {
    onClickAssociatedNovels() {
      this.isModeAssociatedNovels = true;
    },
    onClickNonAssociatedNovels() {
      this.isModeAssociatedNovels = false;
    },
  },
});

interface Props {}

interface Data {
  isLoading: boolean;
  isModeAssociatedNovels: boolean;
}

interface Computed {
  novels: Novel[];
  novelsFromNolaNovel: NovelFromNolaNovel[];
  novelFromNolaNovel(novel: Novel): NovelFromNolaNovel | undefined;
  associatedNovels: Novel[];
  nonAssociatedNovels: Novel[];
}

interface Methods {
  onClickAssociatedNovels(): void;
  onClickNonAssociatedNovels(): void;
}
