
import Vue from "vue";
import SiteFooter from "@/components/organisms/Footer.vue";
import Loading from "@/components/atoms/Loading.vue";
import { showNotifyDialog } from "@/lib/dialog";
import axiosBase from "axios";

const Paginate = require("vuejs-paginate");

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_MICROCMS_API_KEY },
});

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  el: ".news",
  components: {
    SiteFooter,
    Loading,
    Paginate,
  },
  data() {
    return {
      news: [],
      limit: 15,
      currentPage: 1,
      totalCount: 0,
    };
  },
  computed: {
    getPageCount() {
      return Math.ceil(this.totalCount / this.limit);
    },
  },
  methods: {
    async getNews() {
      const newsRequest = await axios.get(`/news?limit=${this.limit}&offset=${(this.currentPage - 1) * this.limit}`);
      if (newsRequest.status !== 200) {
        await showNotifyDialog({
          title: "エラー",
          content: "お知らせの取得に失敗しました。",
        });
      }
      this.news = newsRequest.data.contents;
      this.totalCount = newsRequest.data.totalCount;
    },
    readNews() {
      const latestNews = this.news[0];
      this.$store.dispatch("generalModule/readNews", latestNews);
    },
    clickCallback(pageNum) {
      this.currentPage = Number(pageNum);
      this.getNews();
      window.scrollTo(0, 0);
    },
  },
  async created() {
    await this.getNews();
    this.readNews();
  },
});

interface Props {}

interface Data {
  news: object[];
  limit: number;
  currentPage: number;
  totalCount: number;
}

interface Computed {
  getPageCount: number;
}

interface Methods {
  getNews(): Promise<void>;
  readNews(): void;
  clickCallback(pageNum: any): void;
}
