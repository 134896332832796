
import Vue, { PropType } from "vue";
import ImageView from "@/components/atoms/ImageView.vue";
import format from "date-fns/format";
import { Novel, TCopyNovelPayload } from "@/lib/models";
import PinIcon from "icons/Pin.vue";
import PinOutlineIcon from "icons/PinOutline.vue";
import isMobile from "ismobilejs";
import DotsHorizontalCircleOutlineIcon from "icons/DotsHorizontalCircleOutline.vue";
import DownloadIcon from "icons/Download.vue";
import TrashCanOutlineIcon from "icons/TrashCanOutline.vue";
import ContentCopyIcon from "icons/ContentCopy.vue";
import NovelDeleteConfirm, { NovelDeleteConfirmProps } from "@/components/ui/novel/NovelDeleteConfirm.vue";
import { Dialog } from "@/lib/utils";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/dialogs/SimpleDialog.vue";
import CopyNovelDialog from "@/components/ui/CopyNovelDialog.vue";
import PizZip from "pizzip";
import { extractParagraphInfo } from "@/lib/docxParser";

interface Props {
  novel: Novel;
  isMenuOpen: boolean;
}

interface Data {
  isMouseHover: boolean;
}

interface Computed {
  pinned?: boolean;
  toUpdatedAt: string;
  toCreatedAt: string;
  getImageKey?: string;
  isPC: boolean;
  getFileName: (fileName: string) => string;
  novelIdFromNolaNovel?: string;
}

interface Methods {
  onMouseOver(): void;
  onMouseLeave(): void;
  onClickPinned(): void;
  showCopyNovelDialog(): void;
  importNovel(): void;
  deleteNovel(): void;
  onSelectFile(): Promise<void>;
  showNovelDeleteConfirmDialog: (title: string) => void;
  toggleMenu(): void;
  createMultipleManuscript: (payload: { title?: string; content?: string }[]) => Promise<void>;
  copyNovel(copySelectedData: [] | string[]): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ImageView,
    PinIcon,
    PinOutlineIcon,
    DotsHorizontalCircleOutlineIcon,
    DownloadIcon,
    TrashCanOutlineIcon,
    ContentCopyIcon,
  },
  props: {
    novel: Object as PropType<Novel>,
    isMenuOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isMouseHover: false,
    };
  },
  computed: {
    pinned() {
      const { novel } = this;
      return novel.pinned;
    },
    toUpdatedAt() {
      const { novel } = this;
      const { updatedAtLatestDataInNovel } = novel;

      if (updatedAtLatestDataInNovel) return format(updatedAtLatestDataInNovel, "YYYY/MM/DD");
      return "-";
    },
    toCreatedAt() {
      const { novel } = this;
      const { createdAt } = novel;

      if (createdAt) return format(createdAt, "YYYY/MM/DD");
      return "-";
    },
    getImageKey() {
      const {
        novel: { novelId, image },
      } = this;
      if (image && image.startsWith("file:")) {
        return `file:novels/${novelId}/cover.jpg`;
      }
      return image;
    },
    isPC() {
      return !isMobile().any;
    },
    getFileName() {
      return (fileName: string) => {
        const array = fileName.split(".");
        array.pop(); // 拡張子を削除
        return array.join(".");
      };
    },
    novelIdFromNolaNovel() {
      const { novel } = this;

      if (!novel) {
        return undefined;
      }

      const { associatedData } = novel;

      if (!associatedData) {
        return undefined;
      }

      const { nolaNovel } = associatedData;

      if (!nolaNovel) {
        return undefined;
      }

      const { id } = nolaNovel;

      if (!id) {
        return undefined;
      }

      return id;
    },
  },
  methods: {
    onMouseOver() {
      this.isMouseHover = true;
    },
    onMouseLeave() {
      this.isMouseHover = false;
    },
    async onClickPinned() {
      const { $store, novel } = this;

      novel.pinned = !novel.pinned;

      await $store.dispatch("novelModule/updateNovel", novel);
    },
    async showCopyNovelDialog() {
      const { novel } = this;

      this.toggleMenu();
      const copyNovelDialog = new Dialog(CopyNovelDialog);
      const result = await copyNovelDialog.show({ novelId: novel.novelId });
      // copyNovelDialog側で複製ボタンを押下した場合以下の分岐に入り、それ以外はダイアログを閉じたパターン
      if (result) {
        this.copyNovel(result);
      }
    },
    async copyNovel(copySelectedData: [] | string[]) {
      this.$emit("changeLoading", true);
      const { $store, novel } = this;
      try {
        const payload: TCopyNovelPayload = { novelId: novel.novelId };
        if (copySelectedData.length > 0) {
          payload.copySelectedData = copySelectedData;
        }
        const success = await $store.dispatch("novelModule/copyNovel", payload);
        if (success) {
          const completeDialog = new Dialog(SimpleDialog);
          const completeData = {
            title: "作品の複製が完了しました",
            content: `<span>選択した作品を「${novel.title}のコピー」として複製されました。</span><br /><span>該当の作品を開いて確認をお願いいたします。</span>`,
            width: 380,
          };
          completeDialog.show(completeData);
          $store.dispatch("novelModule/initialize");
          $store.dispatch("memoModule/initialize");
        } else {
          throw new Error("Copy novel operation returned false");
        }
      } catch (error) {
        const errorDialog = new Dialog(SimpleDialog);
        const errorData = {
          title: "エラーが発生しました",
          content: `<span>作品の複製に失敗しました。</span><br /><span>ご迷惑をおかけして申し訳ありません。</span><br />原因の解消に関しましては <a href="${process.env.VUE_APP_NOTION_HELP}" target="blank">ヘルプ</a> をご確認ください`,
          isError: true,
          width: 350,
        };
        errorDialog.show(errorData);
      } finally {
        this.$emit("changeLoading", false);
      }
    },
    importNovel() {
      (this.$refs.input as HTMLInputElement).click();
    },
    deleteNovel() {
      const { novel, showNovelDeleteConfirmDialog } = this;
      if (!novel) {
        return;
      }

      const { title } = novel;
      showNovelDeleteConfirmDialog(title);
    },
    async showNovelDeleteConfirmDialog(title) {
      const { novel, novelIdFromNolaNovel } = this;
      const confirmDialog = new Dialog(NovelDeleteConfirm);
      const data: NovelDeleteConfirmProps = {
        novelId: novel.novelId,
        nolaNovelId: novelIdFromNolaNovel,
        title,
      };

      const result = await confirmDialog.show(data);

      if (result) {
        this.$notify({
          title: "削除しました",
          type: "error",
        });

        this.toggleMenu();
      }
    },
    toggleMenu() {
      this.$emit("toggle-menu-click", this.novel.novelId);
    },
    async onSelectFile() {
      const fileList = (this.$refs.input as HTMLInputElement).files;

      if (fileList) {
        const files = Array.from(fileList);
        const payloads = [] as { title?: string; content?: string }[];

        const fileReading = files.map(
          (file) =>
            new Promise((resolve) => {
              const reader = new FileReader();
              const isText = file.type === "text/plain";

              reader.onerror = () => {
                resolve(reader.error);
              };

              if (isText) {
                reader.onload = () => {
                  const text = reader.result as string;
                  payloads.push({ title: this.getFileName(file.name), content: text });
                  resolve(reader.result);
                };
                reader.readAsText(file);
              } else {
                reader.onload = () => {
                  const arrayBuffer = reader.result as ArrayBuffer;

                  const zip = new PizZip(arrayBuffer);
                  const xml = zip.file("word/document.xml")!.asText();
                  const dom = new DOMParser().parseFromString(xml, "application/xml");

                  let txt = "";
                  const paragraphs = dom.firstChild!.firstChild!.childNodes;

                  // eslint-disable-next-line no-undef
                  paragraphs.forEach((pNode: ChildNode) => {
                    txt += extractParagraphInfo(pNode);
                  });

                  payloads.push({ title: this.getFileName(file.name), content: txt });
                  resolve(reader.result);
                };
                reader.readAsArrayBuffer(file);
              }
            })
        );

        try {
          this.$emit("changeLoading", true);

          await Promise.all(fileReading);

          await this.createMultipleManuscript(payloads);

          const completeDialog = new Dialog(SimpleDialog);
          const completeData: SimpleDialogProps = {
            title: "インポートが完了しました",
            content:
              "<span>インポートした原稿が作品内に追加されました。</span><br /><span>作品を開いて確認をお願いいたします。</span>",
            width: 350,
          };
          completeDialog.show(completeData);
        } catch (error) {
          console.error(error);

          const errorDialog = new Dialog(SimpleDialog);
          const errorData: SimpleDialogProps = {
            title: "エラーが発生しました",
            content:
              "<span>原稿のインポートに失敗しました。</span><br /><span>原稿ファイルに問題がないかご確認ください。</span>",
            isError: true,
            width: 350,
          };
          errorDialog.show(errorData);
        } finally {
          this.$emit("changeLoading", false);
          this.toggleMenu();
        }
      }
    },
    // 作品一覧画面では原稿のフォルダ情報を持っていない為、原稿をインポートした際は
    // 必ずすべての原稿にインポートする。フォルダ関連の処理は本画面では実行しない。
    async createMultipleManuscript(payload) {
      const params = payload.map((item) => ({
        novelId: this.novel.novelId,
        title: item.title || "",
        content: item.content || "",
        manuscriptFolderId: null,
      }));

      await this.$store.dispatch("manuscriptModule/createMultipleManuscript", {
        params,
      });
    },
  },
});
