
import Vue from "vue";
import { VueLoading } from "vue-loading-template";
import EventHeader from "@/components/organisms/EventHeader.vue";
import EventThemeItem from "@/components/molecules/EventThemeItem.vue";
import axiosBase from "axios";
import { compareDesc, format } from "date-fns";

const axiosNola = axiosBase.create({
  baseURL: process.env.VUE_APP_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_MICROCMS_API_KEY },
});

const axiosNolaNovel = axiosBase.create({
  baseURL: process.env.VUE_APP_NOLANOVEL_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_NOLANOVEL_MICROCMS_API_KEY },
});

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { VueLoading, EventHeader, EventThemeItem },
  data() {
    return {
      isLoading: true,
      items: [],
    };
  },
  async created() {
    const contestRequestUrl = "/contest?filters=isDisplayedByTheme[equals]true";
    const contestRequest = axiosNola.get(contestRequestUrl);
    const companyRequestUrl = "/company?filters=isDisplayedByTheme[equals]true";
    const companyRequest = axiosNolaNovel.get(companyRequestUrl);

    const result = await Promise.allSettled([contestRequest, companyRequest]);

    if (result[0].status === "fulfilled")
      this.items = result[0].value.data.contents.map((item: any) => ({ ...item, dataType: "contest" }));
    if (result[1].status === "fulfilled")
      this.items = [
        ...this.items,
        ...result[1].value.data.contents.map((item: any) => ({ ...item, dataType: "company" })),
      ];

    this.items.sort((a, b) => {
      const aPublishedAt = format(a.publishedAt, "YYYY-MM-DD");
      const bPublishedAt = format(b.publishedAt, "YYYY-MM-DD");

      return compareDesc(aPublishedAt, bPublishedAt);
    });

    this.isLoading = false;
  },
});

interface Props {}

interface Data {
  isLoading: boolean;
  items: { [key: string]: any }[];
}

interface Computed {}

interface Methods {}
