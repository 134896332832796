
import Vue from "vue";
import ContestRow from "@/components/molecules/ContestRow.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ContestRow,
  },
  props: {
    contests: Array,
  },
});

interface Props {
  contests: object[];
}

interface Data {}

interface Computed {}

interface Methods {}
