
import Vue from "vue";
import ImageView from "@/components/atoms/ImageView.vue";
import { Novel } from "@/lib/models";

interface Computed {
  novel: Novel | null;
  imageKey?: string | null;
}

interface Props {
  novelId: string;
  expand: boolean;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: { ImageView },

  props: {
    novelId: String,
    expand: Boolean,
  },

  computed: {
    novel() {
      const { novelId } = this;
      const { getters } = this.$store;
      return getters["novelModule/novel"](novelId);
    },
    imageKey() {
      if (!this.novel) {
        return null;
      }

      if (this.novel && this.novel.image && this.novel.image.startsWith("file:")) {
        return `file:novels/${this.novelId}/cover.jpg`;
      }

      return this.novel.image;
    },
  },

  methods: {
    submit() {
      const { novelId } = this;
      this.$router.push({ name: "detail", params: { novelId } });
    },
  },
});
