
import Vue from "vue";
import { Route } from "vue-router";

interface Data {
  isCurrent: boolean;
  isMouseHover: boolean;
}

interface Methods {
  onClick: () => void;
  onMouseOver: () => void;
  onMouseLeave: () => void;
}

interface Props {
  link: string;
  text: string;
  icon: string;
  novelId: string;
  expand: boolean;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  data() {
    return {
      isCurrent: this.$route.name === this.link,
      isMouseHover: false,
    };
  },
  props: {
    link: String,
    text: String,
    icon: String,
    novelId: String,
    expand: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick() {
      if (!this.isCurrent) {
        this.$router.push({ name: this.link, params: { novelId: this.novelId } });
      }
    },
    onMouseOver() {
      this.isMouseHover = true;
    },
    onMouseLeave() {
      this.isMouseHover = false;
    },
  },
  watch: {
    $route(to: Route, from: Route) {
      this.isCurrent = to.name === this.link;
    },
  },
});
