
/* eslint-disable global-require */
import Vue from "vue";
import SideMenuLink from "@/components/atoms/SideMenuLink.vue";
import SideMenuExpandEditableLink from "@/components/atoms/SideMenuExpandEditableLink.vue";
import SideMenuExpandLink from "@/components/atoms/SideMenuExpandLink.vue";

interface Computed {
  sideMenuItems: SideMenuItem[];
}

interface Props {
  novelId: string;
  expand: boolean;
}

type SideMenuItem = {
  text: string;
  icon?: string;
  link: string;
  canExpand: boolean;
  canEdit: boolean;
  children?: SideMenuItem[];
};

const sideMenuItems: SideMenuItem[] = [
  {
    text: "テーマ",
    icon: require("@/assets/img/menu-icon/theme.png"),
    link: "theme",
    canExpand: false,
    canEdit: false,
  },
  {
    text: "構成",
    icon: require("@/assets/img/menu-icon/structure.svg"),
    link: "plot",
    canExpand: true,
    canEdit: false,
    children: [
      {
        text: "プロット",
        icon: require("@/assets/img/menu-icon/plot.png"),
        link: "plot",
        canExpand: false,
        canEdit: false,
      },
      {
        text: "時系列",
        icon: require("@/assets/img/menu-icon/table_chart.svg"),
        link: "timeline",
        canExpand: true,
        canEdit: false,
      },
    ],
  },
  {
    text: "執筆",
    icon: require("@/assets/img/menu-icon/edit.png"),
    link: "editor",
    canExpand: false,
    canEdit: false,
  },
  {
    text: "資料",
    icon: require("@/assets/img/menu-icon/materials.png"),
    link: "materials",
    canExpand: true,
    canEdit: true,
  },
  {
    text: "メモ",
    icon: require("@/assets/img/icon/file-document-edit-outline.png"),
    link: "memoInNovel",
    canExpand: false,
    canEdit: false,
  },
];

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: { SideMenuLink, SideMenuExpandLink, SideMenuExpandEditableLink },
  props: {
    novelId: String,
    expand: Boolean,
  },
  computed: {
    sideMenuItems: () => sideMenuItems,
  },
});
