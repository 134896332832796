
import Vue from "vue";
import SideMenuLinkSp from "@/components/atoms/SideMenuLinkSP.vue";
import SideMenuLinkList from "@/components/organisms/lists/SideMenuLinkList.vue";
import SideMenuNovel from "@/components/molecules/SideMenuNovel.vue";
import { Route } from "vue-router";

interface Data {
  expand: boolean;
  showMenu: boolean;
}

interface Computed {
  expandsSideMenu: boolean;
}

interface Methods {
  onClickExpand(): void;
}

interface Props {
  novelId: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { SideMenuLinkList, SideMenuLinkSp, SideMenuNovel },

  async created() {
    // 最新の作品情報をstateに格納する
    const { $store, novelId } = this;
    await $store.dispatch("novelModule/initialize");
    $store.dispatch("novelModule/fetchNovel", { novelId });
    $store.dispatch("novelModule/initializeExpandsSideMenu");
  },

  props: {
    novelId: { type: String, required: true },
  },

  data() {
    return {
      expand: true,
      showMenu: true,
    };
  },

  watch: {
    $route(newRoute: Route, oldRoute: Route) {
      if (newRoute.name === "editor" && newRoute.params.manuscriptKey != null) {
        this.showMenu = false;
        return;
      }

      this.showMenu = true;
    },
    expandsSideMenu: {
      handler(expandsSideMenu: boolean) {
        this.expand = expandsSideMenu;
      },
      immediate: true,
    },
  },

  computed: {
    expandsSideMenu() {
      return this.$store.getters["novelModule/expandsSideMenu"];
    },
  },

  methods: {
    onClickExpand() {
      this.$store.dispatch("novelModule/toggleSideMenu");
    },
  },
});
