
import { UserClient } from "@/lib/clients";
import {
  AnalyticsDataType,
  AnalyticsFromNolaNovel,
  AnalyticsItem,
  AnalyticsTerm,
  UserFromNolaNovel,
} from "@/lib/models";
import { format, subDays } from "date-fns";
import Vue from "vue";

const userClient = new UserClient();

export default Vue.extend<Data, Methods, Computed, Props>({
  async created() {
    const { nolaNovel } = await userClient.checkConnectedOtherServices();

    if (!nolaNovel.result) return;

    let userFromNolaNovel = this.$store.getters["nolaNovelModule/user"];

    if (!userFromNolaNovel) userFromNolaNovel = await this.$store.dispatch("nolaNovelModule/fetchUser");

    await this.$store.dispatch("nolaNovelModule/fetchAnalytics", [
      {
        targetId: userFromNolaNovel.id,
        type: AnalyticsDataType.PV_EPISODE,
        term: AnalyticsTerm.DAILY,
        date: format(subDays(new Date(), 1), "YYYYMMDD"),
      },
      {
        targetId: userFromNolaNovel.id,
        type: AnalyticsDataType.BOOKMARK,
        term: AnalyticsTerm.ALL,
      },
    ]);

    this.existsUserOnNolaNovel = true;
  },
  data() {
    return {
      existsUserOnNolaNovel: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["nolaNovelModule/user"];
    },
    analytics() {
      return this.$store.getters["nolaNovelModule/analytics"];
    },
    lastDayPV() {
      const { user } = this;
      if (!user) return 0;

      const lastDay = format(subDays(new Date(), 1), "YYYYMMDD");
      const pv = this.$store.getters["nolaNovelModule/analyticsById"](`PV#EPISODE#DAILY#${lastDay}`, user.id) as
        | AnalyticsItem
        | undefined;
      return pv ? pv.count : 0;
    },
    allBookmark() {
      const { user } = this;
      if (!user) return 0;

      const bookmark = this.$store.getters["nolaNovelModule/analyticsById"]("BOOKMARK#ALL", user.id) as
        | AnalyticsItem
        | undefined;
      return bookmark ? bookmark.count : 0;
    },
  },
});

interface Props {}

interface Data {
  existsUserOnNolaNovel: boolean;
}

interface Computed {
  user: UserFromNolaNovel;
  analytics: AnalyticsFromNolaNovel[];
  lastDayPV: number;
  allBookmark: number;
}

interface Methods {}
