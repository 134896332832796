
import Vue from "vue";
import axiosBase from "axios";
import ChevronDownIcon from "icons/ChevronDown.vue";
import ImageView from "@/components/atoms/ImageView.vue";
import { ContestEntries } from "@/lib/models/nolaContest";
import { Novel } from "@/lib/models";
import { format } from "date-fns";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_MICROCMS_API_KEY },
});

const placeholderImage = "/img/placeholders/novel.png";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { ChevronDownIcon, ImageView },
  data() {
    return {
      contests: [],
      openedContests: [],
      placeholderImage,
    };
  },
  async created() {
    this.$store.dispatch("nolaContestModule/fetchContestEntries");
    this.$store.dispatch("novelModule/initialize");
  },
  computed: {
    contestEntries() {
      return this.$store.getters["nolaContestModule/contestEntries"];
    },
    contestImage() {
      return (contestId) => {
        const contest = this.contests.find((contest: any) => contest.contestId === contestId);
        return contest && `${contest.image.url}?fit=crop&w=200&h=100`;
      };
    },
    isOpen() {
      return (contestId) => this.openedContests.includes(contestId);
    },
    novel() {
      return (novelId) => this.$store.getters["novelModule/novel"](novelId);
    },
    novelImage() {
      return (novelId) => {
        const novel = this.novel(novelId);
        if (!novel) return undefined;

        const { image } = novel;
        return image && image.startsWith("file:") ? `file:novels/${novelId}/cover.jpg` : image;
      };
    },
    novelTitle() {
      return (novelId) => {
        const novel = this.novel(novelId);
        return novel && novel.title;
      };
    },
    formatDate() {
      return (date) => format(date, "YYYY/MM/DD");
    },
  },
  methods: {
    onClickContest(contestId) {
      if (this.openedContests.includes(contestId)) {
        this.openedContests = this.openedContests.filter((id) => id !== contestId);
      } else {
        this.openedContests.push(contestId);
      }
    },
    onClickNovel(novelId) {
      this.$router.push({ name: "editor", params: { novelId } });
    },
  },
  watch: {
    contestEntries: {
      async handler(value: ContestEntries[]) {
        if (value.length > 0) {
          const filterParams = value.reduce(
            (acc, cur) => `${acc}${acc && "[or]"}contestId[equals]${cur.contestId}`,
            ""
          );
          const result = await axios.get(`/contest?filters=${filterParams}&limit=100`);
          this.contests = result.data.contents;
        }
      },
      deep: true,
    },
  },
});

interface Props {}

interface Data {
  contests: { [key: string]: any }[];
  openedContests: string[];
  placeholderImage: string;
}

interface Computed {
  contestEntries: ContestEntries[];
  contestImage: (contestId: string) => string | undefined;
  isOpen: (contestId: string) => boolean;
  novel: (novelId: string) => Novel | undefined;
  novelImage: (novelId: string) => string | undefined;
  novelTitle: (novelId: string) => string | undefined;
  formatDate: (date: number) => string;
}

interface Methods {
  onClickContest: (contestId: string) => void;
  onClickNovel: (novelId: string) => void;
}
