
import Vue from "vue";
import MemoButton from "@/components/atoms/MemoButton.vue";
import NovelCard from "@/components/atoms/NovelCover.vue";
import ButtonRound from "@/components/atoms/ButtonRound.vue";
import SelectBox from "@/components/molecules/SelectBox.vue";
import {
  genre as genreList,
  category as categoryList,
  workStatus as workStatusList,
  sort as sortList,
} from "@/lib/novelSettings";
import { Novel, NovelSetting } from "@/lib/models";
import clone from "lodash/cloneDeep";
import { compareAsc, compareDesc } from "date-fns";
import isMobile from "ismobilejs";
import PizZip from "pizzip";
import { extractParagraphInfo } from "@/lib/docxParser";
import FileImportOutlineIcon from "icons/FileImportOutline.vue";
import { Dialog } from "@/lib/utils";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/dialogs/SimpleDialog.vue";
import FileImportDescriptionDialog, {
  FileImportDescriptionDialogProps,
} from "@/components/ui/FileImportDescriptionDialog.vue";
import MaskedLoading from "@/components/atoms/MaskedLoading.vue";

export default Vue.extend<Data, Methods, Computed, {}>({
  components: { MemoButton, NovelCard, ButtonRound, SelectBox, FileImportOutlineIcon, MaskedLoading },
  data() {
    return {
      genreList,
      categoryList,
      workStatusList,
      sortList,
      openMenuId: null,
      isLoading: false,
    };
  },
  computed: {
    novels() {
      let novels = clone(this.$store.getters["novelModule/novels"] as Novel[]);

      const { genre, category, workStatus, sort, workStatusList, isPC } = this;

      /** フィルタリング */
      if (genre) {
        novels = novels.filter((novel) => novel.genre === genre.id);
      }
      if (category) {
        novels = novels.filter((novel) => novel.category && novel.category.includes(category.id));
      }
      novels = novels.filter((novel) => {
        if (workStatus.id === workStatusList[0].id) {
          return novel.workStatus === null || novel.workStatus === workStatus.id;
        }
        return novel.workStatus === workStatus.id;
      });

      /** ソーティング */
      if (sort) {
        switch (sort.id) {
          case "createdAtAsc":
            novels.sort((x, y) => compareAsc(x.createdAt!, y.createdAt!));
            break;
          case "createdAtDesc":
            novels.sort((x, y) => compareDesc(x.createdAt!, y.createdAt!));
            break;
          case "updatedAtAsc":
            novels.sort((x, y) => compareAsc(x.updatedAtLatestDataInNovel!, y.updatedAtLatestDataInNovel!));
            break;
          case "updatedAtDesc":
            novels.sort((x, y) => compareDesc(x.updatedAtLatestDataInNovel!, y.updatedAtLatestDataInNovel!));
            break;
          case "titleAsc":
            novels.sort((x, y) => x.title.localeCompare(y.title));
            break;
          case "titleDesc":
            novels.sort((x, y) => y.title.localeCompare(x.title));
            break;
          default:
            break;
        }
      }

      /** ピン留め */
      if (isPC) {
        const pinnedNovels = novels.filter((novel) => novel.pinned);
        const unpinnedNovels = novels.filter((novel) => !novel.pinned);
        novels = [...pinnedNovels, ...unpinnedNovels];
      }

      return novels;
    },
    genre() {
      return this.$store.getters["novelModule/genre"];
    },
    category() {
      return this.$store.getters["novelModule/category"];
    },
    workStatus() {
      return this.$store.getters["novelModule/workStatus"];
    },
    sort() {
      return this.$store.getters["novelModule/sort"];
    },
    isPC() {
      return !isMobile().any;
    },
    getFileName() {
      return (fileName) => {
        const array = fileName.split(".");
        array.pop(); // 拡張子を削除
        return array.join(".");
      };
    },
    getFolderName() {
      return (file) => file.webkitRelativePath.replace(`/${file.name}`, "");
    },
  },
  methods: {
    selectGenre(item) {
      this.$store.dispatch("novelModule/selectGenre", item);
    },
    unselectGenre() {
      this.$store.dispatch("novelModule/selectGenre", null);
    },
    selectCategory(item) {
      this.$store.dispatch("novelModule/selectCategory", item);
    },
    unselectCategory() {
      this.$store.dispatch("novelModule/selectCategory", null);
    },
    selectWorkStatus(item) {
      this.$store.dispatch("novelModule/selectWorkStatus", item);
    },
    selectSort(item) {
      this.$store.dispatch("novelModule/selectSort", item);
    },
    unselectSort() {
      this.$store.dispatch("novelModule/selectSort", null);
    },
    onClickImportFile() {
      const descriptionDialog = new Dialog(FileImportDescriptionDialog);
      const descriptionData: FileImportDescriptionDialogProps = {
        title: "作品をNolaにインポートできます",
        handler: () => {
          (this.$refs.input as HTMLInputElement).click();
        },
      };
      descriptionDialog.show(descriptionData);
    },
    async onSelectFile() {
      const fileList = (this.$refs.input as HTMLInputElement).files;

      if (fileList) {
        // サポートしているファイル形式以外を除外
        const files = Array.from(fileList);

        const supportedFileList = files.filter((file) => {
          const { type, name } = file;
          const textFileType = "text/plain";
          const docxFileType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          const tempFileRegExp = new RegExp(/^~\$.*.docx$/); // Wordの一時ファイル

          return (type === textFileType || type === docxFileType) && !tempFileRegExp.test(name);
        });

        // フォルダ名を作品名として作品の新規作成
        const novelId = await this.createNovel(this.getFolderName(supportedFileList![0]));

        // ファイルを読み込んで原稿を新規作成
        const payloads = [] as { title?: string; content?: string }[];
        const fileReading = supportedFileList.map(
          (file) =>
            new Promise((resolve) => {
              const reader = new FileReader();
              const isText = file.type === "text/plain";

              reader.onerror = () => {
                resolve(reader.error);
              };

              if (isText) {
                reader.onload = () => {
                  const text = reader.result as string;
                  payloads.push({ title: this.getFileName(file.name), content: text });
                  resolve(reader.result);
                };
                reader.readAsText(file);
              } else {
                reader.onload = () => {
                  const arrayBuffer = reader.result as ArrayBuffer;

                  const zip = new PizZip(arrayBuffer);
                  const xml = zip.file("word/document.xml")!.asText();
                  const dom = new DOMParser().parseFromString(xml, "application/xml");

                  let txt = "";
                  const paragraphs = dom.firstChild!.firstChild!.childNodes;

                  // eslint-disable-next-line no-undef
                  paragraphs.forEach((pNode: ChildNode) => {
                    txt += extractParagraphInfo(pNode);
                  });

                  payloads.push({ title: this.getFileName(file.name), content: txt });
                  resolve(reader.result);
                };
                reader.readAsArrayBuffer(file);
              }
            })
        );

        try {
          this.$emit("changeLoading", true);

          await Promise.all(fileReading);

          await this.createMultipleManuscript(novelId, payloads);

          const completeDialog = new Dialog(SimpleDialog);
          const completeData: SimpleDialogProps = {
            title: "インポートが完了しました",
            content:
              "<span>インポートした原稿が作品内に追加されました。</span><br /><span>該当の原稿を開いて確認をお願いいたします。</span>",
            close: "作品を開く",
            actionHandler: () => {
              this.$router.push({ name: "editor", params: { novelId } });
            },
            width: 350,
          };
          completeDialog.show(completeData);
        } catch (error) {
          console.error(error);

          const errorDialog = new Dialog(SimpleDialog);
          const errorData: SimpleDialogProps = {
            title: "エラーが発生しました",
            content:
              "<span>原稿のインポートに失敗しました。</span><br /><span>フォルダ構成や原稿ファイルに問題がないかご確認ください。</span>",
            isError: true,
            width: 350,
          };
          errorDialog.show(errorData);
        } finally {
          this.$emit("changeLoading", false);
        }
      }
    },
    async createNovel(title) {
      const { novelId } = (await this.$store.dispatch("novelModule/createNovel", {
        title,
      })) as Novel;

      return novelId;
    },
    async createMultipleManuscript(novelId, payload) {
      const params = payload.map((item) => ({
        novelId,
        title: item.title || "",
        content: item.content || "",
      }));

      await this.$store.dispatch("manuscriptModule/createMultipleManuscript", {
        params,
      });
    },
    handleToggleMenu(novelId) {
      this.openMenuId = this.openMenuId === novelId ? null : novelId;
    },
    changeLoading(value) {
      this.isLoading = value;
    },
  },
});

interface Data {
  genreList: NovelSetting[];
  categoryList: NovelSetting[];
  workStatusList: NovelSetting[];
  sortList: NovelSetting[];
  openMenuId: null | string;
  isLoading: boolean;
}

interface Computed {
  novels: Novel[];
  genre: NovelSetting | null;
  category: NovelSetting | null;
  workStatus: NovelSetting;
  sort: NovelSetting | null;
  isPC: boolean;
  getFileName: (fileName: string) => string;
  // NOTE: build時にFile型のwebkitRelativePathが存在しないプロパティとなってしまうため、any型にしている
  getFolderName: (file: any) => string;
}

interface Methods {
  selectGenre(item: NovelSetting): void;
  unselectGenre(): void;
  selectCategory(item: NovelSetting): void;
  unselectCategory(): void;
  selectWorkStatus(item: NovelSetting): void;
  selectSort(item: NovelSetting): void;
  unselectSort(): void;
  onClickImportFile(): void;
  onSelectFile(): Promise<void>;
  createNovel(title: string): Promise<string>;
  createMultipleManuscript: (novelId: string, payload: { title?: string; content?: string }[]) => Promise<void>;
  handleToggleMenu(novelId: string): void;
  changeLoading(value: boolean): void;
}
