
import Vue from "vue";

const Tabs = {
  theme: "theme",
  contest: "contest",
  company: "company",
} as const;
type TabType = typeof Tabs[keyof typeof Tabs];

export default Vue.extend<Data, Methods, Computed, Props>({
  data() {
    return {
      tabs: Tabs,
    };
  },
  computed: {
    isActiveTab() {
      return (tab) => {
        const { path } = this.$route;
        return path === `/event/${tab}`;
      };
    },
  },
  methods: {
    onChangeTab(tab) {
      this.$router.push({ path: `/event/${tab}` });
    },
  },
});

interface Props {}

interface Data {
  tabs: typeof Tabs;
}

interface Computed {
  isActiveTab(tab: TabType): boolean;
}

interface Methods {
  onChangeTab(tab: TabType): void;
}
