import { render, staticRenderFns } from "./EntryProfile.vue?vue&type=template&id=79da4969&scoped=true"
import script from "./EntryProfile.vue?vue&type=script&lang=ts"
export * from "./EntryProfile.vue?vue&type=script&lang=ts"
import style0 from "./EntryProfile.vue?vue&type=style&index=0&id=79da4969&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79da4969",
  null
  
)

export default component.exports