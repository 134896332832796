
import Vue from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    isModeAssociatedNovels: Boolean,
  },
  computed: {
    title() {
      return this.isModeAssociatedNovels ? "投稿されている作品はありません" : "読者に公開できる作品がありません";
    },
    message() {
      return this.isModeAssociatedNovels
        ? "作品を投稿してみましょう。"
        : "新しく作品を作成して、執筆をはじめましょう。";
    },
    actionName() {
      return this.isModeAssociatedNovels ? "作品を投稿する" : "作品を執筆する";
    },
  },
  methods: {
    onClickButton() {
      const { isModeAssociatedNovels } = this;
      if (isModeAssociatedNovels) this.$emit("clickNonAssociatedNovels");
      else this.$router.push({ name: "novelCreate" });
    },
  },
});

interface Props {
  isModeAssociatedNovels: boolean;
}

interface Data {}

interface Computed {
  title: string;
  message: string;
  actionName: string;
}

interface Methods {
  onClickButton(): void;
}
