
import Vue from "vue";
import TextField from "@/components/molecules/TextField.vue";
import ButtonDefault from "@/components/atoms/ButtonDefault.vue";
import PaymentDescription from "@/components/organisms/subscription/PaymentDescription.vue";
import SiteFooter from "@/components/organisms/Footer.vue";

import { Auth } from "aws-amplify";
import { Dialog } from "@/lib/utils";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/SimpleDialog.vue";
import UserDeleteDialog, { UserDeleteDialogProps } from "@/components/ui/UserDeleteDialog.vue";
import { isTwitterLogin } from "@/lib/twitterAuth";
import { UserClient } from "@/lib/clients";
import { User, Plan } from "@/lib/models";
import { db } from "@/lib/indexeddb";

const userClient = new UserClient();

export default Vue.extend<Data, Methods, Computed, {}>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    TextField,
    ButtonDefault,
    PaymentDescription,
    SiteFooter,
  },
  data() {
    return {
      id: "",
      email: "",
      userName: null,
      loaded: false,
      isTwitterLogin: false,
      isProcessing: false,
    };
  },
  computed: {
    changed() {
      if (this.user) {
        return this.userName !== this.user.name;
      }
      return false;
    },
    user() {
      const user: User = this.$store.getters["userModule/user"];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.userName = user && user.name;
      return user;
    },
    plan() {
      return this.$store.getters["userModule/plan"] as Plan;
    },
    isSchoolPlan() {
      return this.plan === Plan.school;
    },
  },
  methods: {
    async logout() {
      const result = await Auth.signOut();
      if (!result) {
        localStorage.clear();
      }
      window.sessionStorage.removeItem("vuex"); // VuexのStoreを初期化
      db.delete(); // IndexedDBの初期化
      window.location.href = "/";
    },
    async save() {
      const { userName } = this;
      this.$store.dispatch("userModule/updateUser", userName);
    },
    async onClickDeleteAccount() {
      this.isProcessing = true;

      const results = await userClient.checkConnectedOtherServices();

      this.isProcessing = false;

      if (results.nolaPublishing.error || results.nolaNovel.error) {
        let errorMessage = "";
        if (results.nolaNovel.error) {
          errorMessage += `Nolaノベル：${results.nolaNovel.error}`;
        }
        if (results.nolaPublishing.error) {
          errorMessage += errorMessage && "\n";
          errorMessage += `Nola出版：${results.nolaPublishing.error}`;
        }
        const warningDialog = new Dialog(SimpleDialog);
        const options: SimpleDialogProps = {
          title: "現在、アカウントの削除ができません。",
          content: `大変申し訳ございません。現在Nola関連サービス（Nolaノベル、Nola出版など）で障害が発生しており、アカウントの削除ができません。\n復旧に向け対応を進めておりますので、明日以降に再度ご対応いただけると幸いです。\nなお、ご不明点などございましたらお問い合わせまでご連絡をお願いいたします。\n\nエラー内容\n${errorMessage}`,
        };
        warningDialog.show(options);
        return;
      }

      const confirmDialog = new Dialog(UserDeleteDialog);
      const data: UserDeleteDialogProps = {
        existsNolaPublishingUser: results.nolaPublishing.result!,
        existsNolaNovelUser: results.nolaNovel.result!,
        positive: "削除する",
        positiveCallback: async () => this.deleteUser(),
      };
      confirmDialog.show(data);
    },
    async deleteUser() {
      this.$store.dispatch("userModule/deleteUser", {
        email: this.email,
        callback: async () => {
          await Auth.signOut();
          window.localStorage.clear();
          window.sessionStorage.removeItem("vuex"); // VuexのStoreを初期化
          db.delete(); // IndexedDBの初期化
          this.$router.push({ name: "accountDeleted" });
        },
      });
    },
  },
  async created() {
    this.isTwitterLogin = isTwitterLogin();
    const user = await Auth.currentUserPoolUser().catch((err) => null);
    if (user) {
      this.id = user.signInUserSession.idToken.payload.sub;

      const email = user.attributes.email || user.username;
      this.email = email;
    }

    this.$store.dispatch("userModule/initialize");
    this.loaded = true;
  },
});

interface Data {
  id: string;
  email: string;
  userName: string | null;
  loaded: boolean;
  isTwitterLogin: boolean;
}

interface Computed {
  changed: boolean;
  user: User;
  plan: Plan;
  isSchoolPlan: boolean;
}

interface Methods {
  logout: () => void;
  save: () => void;
  onClickDeleteAccount: () => void;
  deleteUser: () => void;
}
