
import Vue from "vue";
import TextField from "@/components/molecules/TextField.vue";
import { Auth } from "aws-amplify";
import { showNotifyDialog } from "@/lib/dialog";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { TextField },
  data() {
    return {
      passwordOld: "",
      passwordNew: "",
      passwordNewConfirm: "",
      message: "",
      processing: false,
    };
  },
  methods: {
    async submit() {
      if (this.passwordNew !== this.passwordNewConfirm) {
        this.message = "「新しいパスワード」と「新しいパスワード（確認）」が一致していません。";
        return;
      }
      if (this.passwordNew.length < 7) {
        this.message = "「新しいパスワード」は7文字以上で設定してください。";
        return;
      }
      if (this.passwordOld.length < 7) {
        this.message = "「現在のパスワード」は7文字以上です。";
        return;
      }

      this.processing = true;
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, this.passwordOld, this.passwordNew);
        this.message = "";
        await showNotifyDialog({ content: "パスワードが正常に変更されました。" });
        this.$router.push({ name: "myPage" });
      } catch (error: any) {
        console.error(error);
        const { code } = error;
        if (code === "NotAuthorizedException") {
          this.message = "現在のパスワードが正しくありません。";
          this.passwordOld = "";
        } else if (code === "InvalidPasswordException") {
          this.message = "「新しいパスワード」には、英大文字小文字が1つ以上必要です。";
        } else if (code === "LimitExceededException") {
          this.message = "試行回数の制限に達しました。しばらく待ってから再度お試しください。";
        } else if (code === "NetworkError") {
          this.message = "ネットワークエラーが発生しました。通信環境の良い場所で再度お試しください。";
        } else {
          this.message = `エラーが発生しました。内容：${error.message || error}`;
        }
      } finally {
        this.processing = false;
      }
    },
  },
});
