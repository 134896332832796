
import Vue from "vue";
import CupertinoAlertDialog from "@/components/ui/dialogs/CupertinoAlertDialog.vue";

interface Props {
  title: string;
  handler: () => void;
}

interface Data {
  width: number;
  fontSize: string;
}

interface Computed {}

interface Methods {
  onClickOutSide: () => void;
  onClickImport: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CupertinoAlertDialog },

  props: {
    title: String,
    handler: Function,
  },

  data() {
    return {
      width: 600,
      fontSize: "1rem",
    };
  },

  methods: {
    onClickOutSide() {
      this.$close(false);
    },
    onClickImport() {
      this.handler();
      this.$close(true);
    },
  },
});

export type FileImportDescriptionDialogProps = Props;
