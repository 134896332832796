
import Vue, { PropType } from "vue";
import CheckboxBlankOutlineIcon from "icons/CheckboxBlankOutline.vue";
import CheckboxMarkedIcon from "icons/CheckboxMarked.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    CheckboxBlankOutlineIcon,
    CheckboxMarkedIcon,
  },
  model: {
    prop: "checked",
    event: "check",
  },
  props: {
    checked: Boolean as PropType<boolean>,
    text: String as PropType<string>,
  },
  methods: {
    onClick() {
      this.$emit("check", !this.checked);
    },
  },
});

interface Props {
  checked: boolean;
  text?: string;
}

interface Data {}

interface Computed {}

interface Methods {
  onClick(): void;
}
