
import Vue, { PropType } from "vue";
import CloseCircleIcon from "icons/CloseCircle.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    CloseCircleIcon,
  },
  props: {
    text: String as PropType<string>,
    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  methods: {
    async onClickDelete() {
      this.$emit("delete");
    },
  },
});

interface Props {
  text: string;
  readonly: boolean;
}

interface Data {}

interface Computed {}

interface Methods {
  onClickDelete(): void;
}
