// //////////////////
// Data Model
// //////////////////

export type NolaContest = {
  /** コンテストID */
  id: string;
  /** コンテスト名 */
  name: string;
  /** コンテストグループ名 */
  groupName?: string;
  /** コンテスト主催者 */
  organizer: string;
  /** コンテスト主催者ID */
  organizerId: string;

  /** 応募開始日時 */
  applicationStart: string;
  /** 応募終了日時 */
  applicationEnd: string;

  /**
   * 応募時の入力項目
   * 未指定の場合はシステムの既定の項目。
   */
  entryItems: ContestEntryItemModel[];
  /**
   * 募集部門
   */
  categories?: ContestCategoryModel[];
  /**
   * 選考段階
   */
  currentStage?: string;

  /**
   * 利用規約
   */
  terms?: string;

  /**
   * プラットフォーム
   */
  platform: string;

  /**
   * 審査スケジュール
   */
  schedule?: Schedule[];
};

export type ContestEntryItemModel = {
  /** 入力項目ID */
  id: string;
  /** 項目名 */
  name: string;
  /** 備考 */
  remarks?: string | string[];
  /** 項目の詳細説明 */
  description?: string;
  /** 必須かどうか */
  optional?: boolean;
  /** 順序 */
  order?: number;
} & EntryItemSchema;

/**
 * 入力項目のスキーマ
 */
type EntryItemSchema =
  | StringSchema
  | NumberSchema
  | SelectSchema
  | FileSchema
  | NolaSynopsisSchema
  | NolaManuscriptSchema
  | NolaPlotSchema
  | NolaCharacterSchema
  | NolaWorldViewsSchema
  | NolaCorrelationSchema;

/** 文字列形式のベーススキーマ */
type BaseStringSchema = {
  min?: number;
  max?: number;
  regex?: string;
  multiLine?: boolean;
};

/** 文字列形式の入力項目のスキーマ */
type StringSchema = BaseStringSchema & {
  type: "string" | "title";
};

/** 数値形式の入力項目のスキーマ */
type NumberSchema = {
  type: "number";
  min?: number;
  max?: number;
  isInt?: boolean;
  multiLine?: undefined;
};

/** 列挙形式の入力項目のスキーマ */
type SelectSchema = {
  type: "select";
  options: string[];
};

/** ファイル形式の入力項目のスキーマ */
type FileSchema = {
  type: "file";
  maxSize: number;
  formats: ("csv" | "excel" | "pdf" | "word")[];
};

/** Nolaのあらすじ形式のスキーマ */
type NolaSynopsisSchema = BaseStringSchema & {
  type: "synopsis";
};

/** Nolaの原稿形式のスキーマ */
type NolaManuscriptSchema = {
  type: "manuscripts";
  min?: number;
  max?: number;
};

/** Nolaのプロット形式のスキーマ */
type NolaPlotSchema = {
  type: "plot";
};

/** Nolaの登場人物形式のスキーマ */
type NolaCharacterSchema = {
  type: "characters";
  min?: number;
  max?: number;
};

type NolaWorldViewsSchema = {
  type: "worldviews";
};

type NolaCorrelationSchema = {
  type: "correlations";
};

/**
 * 募集部門
 */
export type ContestCategoryModel = {
  /** 募集部門ID */
  id: string;
  /** 一般部門 */
  name: string;
  /**
   * 部門での入力項目
   * 未指定の場合は当該コンテストの入力項目。
   */
  entryItems?: ContestEntryItemModel[];
};

/**
 * 応募作品
 */
export type NolaContestData = {
  novelId: string;
  category: string;
  entryItems: { [key: string]: any };
};

/**
 * 応募者
 */
export type NolaContestUser = {
  name?: string;
  nameKana?: string;
  penname?: string | string[];
  email?: string;
  phone?: string;
  gender?: Gender;
  birthdate?: string;
  historyOfAwards?: string;
};

/**
 * 性別
 */
export enum Gender {
  "男性" = 1,
  "女性" = 2,
  "その他" = 9,
}

/**
 * 応募履歴
 */
export interface ContestEntries {
  contestId: string;
  contestName: string;
  entryNovels: EntryNovel[];
}

export interface EntryNovel {
  entryId: string;
  novelId: string;
  categoryId?: string | null;
  categoryName?: string;
  entryStatus: EntryStatusType;
  entryStatusText: string;
  entryTime: number;
}

/**
 * 応募状況
 */
export const EntryStatus = {
  ENTRY: "ENTRY",
  PROGRESS: "PROGRESS",
  REJECTION: "REJECTION",
  AWARDED: "AWARDED",
} as const;
export type EntryStatusType = typeof EntryStatus[keyof typeof EntryStatus];

/**
 * 審査スケジュール
 */
type Schedule = {
  name: string;
  value: string;
};

// //////////////////
// Request Model
// //////////////////

export type EntryContestInput = {
  contestId: string;
  novelId: string;
  category: string;
  platform: string;
  entryItems: { [key: string]: any };
};

// //////////////////
// Response Model
// //////////////////

export type EntryContestOutput = {
  entryId: string;
};
