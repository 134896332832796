
import Vue from "vue";
import { callApi } from "@/lib/api";
import gql from "graphql-tag";
import { Plan } from "@/lib/models";

export default Vue.extend({
  data() {
    return {
      invoiceUrl: null,
      loaded: false,
    };
  },
  async created() {
    const { billingInfo } = await callApi(gql`
      query BillingInfo {
        billingInfo {
          invoiceUrl
        }
      }
    `);
    this.invoiceUrl = billingInfo.invoiceUrl;
    this.loaded = true;
  },
  computed: {
    plan() {
      return this.$store.getters["userModule/plan"] as Plan;
    },
    planName() {
      if (!this.loaded) return "";
      switch (this.plan) {
        case Plan.free:
          return "無料会員";
        case Plan.monthly:
          return "プレミアム会員";
        case Plan.yearly:
          return "プレミアム会員（年間）";
        case Plan.school:
          return "スクール会員";
        default:
          return "";
      }
    },
    isSchoolPlan() {
      return this.plan === Plan.school;
    },
  },
});
