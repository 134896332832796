
import Vue, { PropType } from "vue";
import { createUrlWithUtmParams } from "@/lib/utils/url";
import { NolaItemId, NolaPageName } from "@/lib/utils/analytics";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    company: Object as PropType<Company>,
  },
  computed: {
    id() {
      const { company } = this;
      return company.id;
    },
    bannerImg() {
      const { company } = this;
      return `${company.bannerImg.url}`;
    },
    iconImg() {
      const { company } = this;
      return `${company.iconImg.url}`;
    },
    name() {
      const { company } = this;
      return company.name;
    },
    comment() {
      const { company } = this;
      return company.comment;
    },
  },
  methods: {
    onClickCard() {
      const { id } = this;
      window.open(
        createUrlWithUtmParams(
          `${process.env.VUE_APP_NOLANOVEL_WEB}/company/${id}`,
          NolaPageName.EventCompany,
          NolaItemId.CompanyCard
        ),
        process.env.VUE_APP_NOLANOVEL_NAME
      );
    },
  },
});

interface Props {
  company: Company;
}

interface Data {}

interface Computed {
  id: string;
  bannerImg: string;
  iconImg: string;
  name: string;
  comment: string;
}

interface Methods {
  onClickCard(): void;
}

type Company = {
  [key: string]: any;
};
