
import Vue, { PropType } from "vue";
import CupertinoAlertDialog from "@/components/ui/dialogs/CupertinoAlertDialog.vue";
import CharacterItem from "@/components/atoms/CharacterItem.vue";
import deepEqual from "deep-equal";

interface Props {
  type?: "characters" | "manuscripts";
  title: string;
  description: string;
  items: any[];
  itemsKeyAttribute: string;
  close?: string;
  novelId: string;
  initialSelected?: any[];
}

interface Data {
  selected: any[];
}

interface Computed {
  isChecked: (item: any) => boolean;
  selectedOrder: (item: any) => number | string;
}

interface Methods {
  onClickOutSide: () => void;
  onClickClose: () => void;
  onClickItem: (item: any) => void;
  onClickDeselectAll: () => void;
  onClickSelectAll: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { CupertinoAlertDialog, CharacterItem },

  props: {
    type: String as PropType<"characters" | "manuscripts">,
    title: String,
    description: String,
    items: Array as PropType<any[]>,
    itemsKeyAttribute: {
      type: String,
      default: "id",
      required: false,
    },
    close: {
      type: String,
      default: "閉じる",
      required: false,
    },
    novelId: String as PropType<string>,
    initialSelected: Array as PropType<any[]>,
  },

  data() {
    return {
      selected: [],
    };
  },

  created() {
    const { initialSelected } = this;
    if (initialSelected) {
      this.selected = [...initialSelected];
    }
  },

  computed: {
    isChecked() {
      return (item) => {
        const { selected } = this;
        return selected.some((i) => deepEqual(i, item));
      };
    },
    selectedOrder() {
      return (item) => {
        const { selected } = this;
        const index = selected.findIndex((i) => deepEqual(i, item));
        if (index === -1) {
          return "";
        }
        return index + 1;
      };
    },
  },

  methods: {
    onClickOutSide() {
      this.$close(false);
    },
    onClickClose() {
      this.$close(this.selected);
    },
    onClickItem(item) {
      const { selected, itemsKeyAttribute } = this;
      const index = selected.findIndex((i) => i[itemsKeyAttribute] === item[itemsKeyAttribute]);
      if (index === -1) {
        selected.push(item);
      } else {
        selected.splice(index, 1);
      }
    },
    onClickDeselectAll() {
      this.selected = [];
    },
    onClickSelectAll() {
      this.selected = [...this.items];
    },
  },
});

export type ContestEntryDataDialogProps = Props;
