var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"header"},[_vm._m(0),_c('div',{staticClass:"login"},[_c('router-link',{staticClass:"login-button",attrs:{"to":{ name: 'signIn' }}},[_vm._v(" ログイン ")])],1)]),_c('div',{staticClass:"header-line"}),_c('div',{staticClass:"containar-fluid"},[_vm._m(1),_c('div',{staticClass:"header-line"}),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"contents-line sp"}),_vm._m(7),_c('div',{staticClass:"contents-line",attrs:{"id":"registration"}}),_c('div',{staticClass:"registration"},[_c('h2',[_vm._v("無料会員登録で執筆をはじめる。")]),_vm._m(8),_c('router-link',{staticClass:"registration-button",attrs:{"to":{ name: 'signUp' }}},[_vm._v("新規会員登録")]),_c('router-link',{staticClass:"registration-button",attrs:{"to":{ name: 'signIn' }}},[_vm._v("ログインする")])],1)]),_c('div',{staticClass:"contents-line"}),_c('footer',[_c('ul',[_vm._m(9),_vm._m(10),_c('li',[_c('a',{staticClass:"link",attrs:{"href":_vm.help,"target":"blank"}},[_vm._v("ヘルプ")])]),_c('li',[_c('router-link',{staticClass:"link",attrs:{"to":"/contact"}},[_vm._v("お問い合わせ")])],1)]),_c('p',[_vm._v("©️ Nolaスタッフ")])]),_c('div',{staticClass:"fix-button"},[_c('router-link',{staticClass:"fix-button-1 pc",attrs:{"to":{ name: 'signIn' }}},[_vm._v(" ログインする ")]),_c('router-link',{staticClass:"fix-button-2 pc",attrs:{"to":{ name: 'signUp' }}},[_vm._v(" 新規登録する ")]),_c('transition',{attrs:{"name":"fade-fast"}},[(_vm.scrollY > 40)?[_c('a',{attrs:{"href":"#registration"}},[_c('div',{staticClass:"fix-button-3 sp"},[_vm._v("ログイン / 新規登録")])])]:_vm._e()],2)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"logo"},[_c('img',{staticClass:"header-logo",attrs:{"src":require("@/assets/img/logo.png"),"alt":"小説執筆ツールNola（ノラ）のロゴ"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"top-contents"},[_c('img',{staticClass:"catchcopy-1",attrs:{"src":require("@/assets/img/static/top-catchcopy-6.png"),"alt":"物語を描く全ての人のための作家専用エディタツール"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"top-message"},[_c('img',{staticClass:"img-responsive",attrs:{"src":require("@/assets/img/static/top-message.png"),"alt":"物語の構造と背景を整理する。すべての作家が、文章の表面に現れない領域を深く考え、緻密に、繊細に組み立てることで思い思いの物語を描いています。その瞬間の主人公の感情も、目の前に広がる情景も、読み手がイメージできてしまうのは、そんな過程があるからだと思います。Nola（ノラ）は、原稿の執筆とその過程にある思考を整理する作家専用の執筆ツールです。原稿の執筆はもちろんのこと、物語の裏側にある登場人物や世界観の設定の保存やプロットの管理ができます。"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9-l col-xs-12"},[_c('img',{staticClass:"service-img",attrs:{"src":require("@/assets/img/static/service-img.png"),"alt":"物語を描く全ての人のための作家専用エディタツール"}})]),_c('div',{staticClass:"col-lg-3-r col-xs-12"},[_c('div',{staticClass:"service-txt"},[_c('img',{staticClass:"main-logo",attrs:{"src":require("@/assets/img/logo.png"),"alt":"小説執筆ツールNola（ノラ）のロゴ"}}),_c('p',[_vm._v(" Nolaは、PCとスマホの双方で、データを同期して利用できる執筆ツールです。"),_c('br'),_vm._v("原稿の執筆機能、参考資料の保存機能、プロットの作成機能があります。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3-l col-xs-12"},[_c('div',{staticClass:"screenshot-txt"},[_c('h2',[_vm._v("「プロット機能」")]),_c('h3',[_vm._v("物語のブレない骨組みを設計する。")]),_c('p',[_vm._v("プロット機能では、ものがたりの骨子を「起承転結」で簡単にまとめることができます。")])])]),_c('div',{staticClass:"col-lg-9-r col-xs-12"},[_c('img',{staticClass:"screenshot pc",attrs:{"src":require("@/assets/img/static/service-screen-plot.png"),"alt":"物語を描く全ての人のための作家専用エディタツール"}}),_c('img',{staticClass:"screenshot sp",attrs:{"src":require("@/assets/img/static/service-sp-screen-plot.png"),"alt":"物語を描く全ての人のための作家専用エディタツール"}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3-l col-xs-12 sp"},[_c('div',{staticClass:"screenshot-txt"},[_c('h2',[_vm._v("「登場人物の保存」")]),_c('h3',[_vm._v("物語に登場する人物と場所を設定する。")]),_c('p',[_vm._v("ものがたりに登場するキャラクターや、村や街などの世界観の設定を保存することができます。")])])]),_c('div',{staticClass:"col-lg-9-l col-xs-12"},[_c('img',{staticClass:"screenshot pc",attrs:{"src":require("@/assets/img/static/service-screen-character.png"),"alt":"物語を描く全ての人のための作家専用エディタツール"}}),_c('img',{staticClass:"screenshot sp",attrs:{"src":require("@/assets/img/static/service-sp-screen-character.png"),"alt":"物語を描く全ての人のための作家専用エディタツール"}})]),_c('div',{staticClass:"col-lg-3-r col-xs-12 pc"},[_c('div',{staticClass:"screenshot-txt"},[_c('h2',[_vm._v("「登場人物の保存」")]),_c('h3',[_vm._v("物語に登場する人物と世界観を設定する。")]),_c('p',[_vm._v("ものがたりに登場するキャラクターや、村や街などの世界観の設定を保存することができます。")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3-l col-xs-12"},[_c('div',{staticClass:"screenshot-txt"},[_c('h2',[_vm._v("「原稿の執筆機能」")]),_c('h3',[_vm._v("組み立てた骨子から文章と行間を書く。")]),_c('p',[_vm._v(" 一話ごとに執筆をすることができます。ツール内に保存した登場人物やプロットを見ながら執筆をするのもおすすめです。 ")])])]),_c('div',{staticClass:"col-lg-9-r col-xs-12"},[_c('img',{staticClass:"screenshot pc",attrs:{"src":require("@/assets/img/static/service-screen-editor.png"),"alt":"物語を描く全ての人のための作家専用エディタツール"}}),_c('img',{staticClass:"screenshot sp",attrs:{"src":require("@/assets/img/static/service-sp-screen-editor.png"),"alt":"物語を描く全ての人のための作家専用エディタツール"}})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-lg-12"},[_c('img',{staticClass:"catchcopy-2 pc",attrs:{"src":require("@/assets/img/static/catchcopy-2.png"),"alt":"誰かの心の中に、新しい世界を生み出す"}}),_c('img',{staticClass:"catchcopy-2 sp",attrs:{"src":require("@/assets/img/static/catchcopy-sp-2.png"),"alt":"誰かの心の中に、新しい世界を生み出す"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" 小説を書いてみたり、ゲームシナリオを書いたり、"),_c('br'),_vm._v("文章で何か表現をして、誰かに届けたい人に役立つツールになれるよう日々開発しています。"),_c('br'),_vm._v("ぜひ一度、手にとって使ってみてください。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{staticClass:"link",attrs:{"href":"/terms","target":"blank"}},[_vm._v("利用規約")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{staticClass:"link",attrs:{"href":"/privacy","target":"blank"}},[_vm._v("プライバシーポリシー")])])
}]

export { render, staticRenderFns }