
import Vue from "vue";
import { Route } from "vue-router";
import ExpandedItem from "@/components/atoms/ExpandedItem.vue";
import SortableList from "@/components/molecules/SortableList.vue";
import { ListState, MaterialAttribute } from "@/lib/models";
import { Dialog, isBilling, reOrderData } from "@/lib/utils";
import { SortOrder } from "@/lib/models/sortOrder";
import MaterialAttributeEditDialog, {
  MaterialAttributeEditDialogProps,
} from "@/components/ui/MaterialAttributeEditDialog.vue";
import PlaylistEditIcon from "icons/PlaylistEdit.vue";
import DeleteIcon from "icons/Delete.vue";
import CheckboxBlankOutlineIcon from "icons/CheckboxBlankOutline.vue";
import CheckboxMarkedOutlineIcon from "icons/CheckboxMarkedOutline.vue";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/SimpleDialog.vue";
import DialogVue from "@/components/ui/Dialog.vue";

interface Data {
  isOpen: boolean;
  isCurrent: boolean;
  currentListState: ListState;
  currentName: string;
  removeIcon: unknown;
  reorderableListItems: MaterialTileModel[];
  selectedItems: MaterialTileModel[];
  isMouseHover: boolean;
}

interface Methods {
  initialize: () => void;
  isActive: (item: MaterialTileModel) => boolean;
  showDeleteCheckbox: (item: MaterialTileModel) => boolean;
  showTextInput: (item: MaterialTileModel) => boolean;
  showErrorDialog: (message: string) => void;
  onInput: (event: InputEvent) => void;
  onClick: () => void;
  onLinkClick: (item?: MaterialTileModel) => void;
  onAddButtonClick: () => void;
  onEditIconClick: (event: PointerEvent) => void;
  onDeleteIconClick: (event: PointerEvent) => void;
  onCancelClick: (event: PointerEvent) => void;
  onReorder: (items: MaterialTileModel[]) => void;
  onCheck: (item: MaterialTileModel) => void;
  onUpdateButtonClick: () => void;
  onDeleteButtonClick: () => void;
  addMaterialAttribute: (value: string) => void;
  updateMaterialAttributes: () => void;
  deleteMaterialAttributes: () => void;
  checkedItems: (item: MaterialTileModel) => boolean;
  convertTileModel: (items: MaterialAttribute[]) => MaterialTileModel[];
  pushPage: (item: MaterialTileModel) => void;
  onMouseOver: () => void;
  onMouseLeave: () => void;
}

interface Computed {
  listItems: MaterialTileModel[];
  themeItem: MaterialTileModel;
  isListEditing: boolean;
  isDeleteEditing: boolean;
  isEditing: boolean;
  showUpdateButton: boolean;
  showDeleteButton: boolean;
}

interface Props {
  link: string;
  text: string;
  icon: string;
  novelId: string;
  expand: boolean;
}

type MaterialTileModel = {
  id: string;
  text: string;
  icon: string | unknown;
  link: string;
};

const characterIcon = require("@/assets/img/menu-icon/character.png");
const worldViewIcon = require("@/assets/img/menu-icon/worldview.png");
const correlationIcon = require("@/assets/img/menu-icon/correlation.png");
const materialIcon = require("@/assets/img/menu-icon/material.png");
const removeIcon = require("@/assets/img/icon/remove.png");
const templateIcon = require("@/assets/img/menu-icon/template.png");

const defaultMaterials = ["characters", "worldViews", "correlations"];

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ExpandedItem,
    SortableList,
    PlaylistEditIcon,
    DeleteIcon,
    CheckboxBlankOutlineIcon,
    CheckboxMarkedOutlineIcon,
  },
  data() {
    return {
      isOpen: true,
      isCurrent: this.$route.name === this.link,
      currentListState: ListState.COMPLETE,
      currentName: "",
      removeIcon,
      reorderableListItems: [],
      selectedItems: [],
      isMouseHover: false,
    };
  },
  created() {
    const { initialize } = this;
    initialize();
  },
  props: {
    link: String,
    text: String,
    icon: String,
    novelId: String,
    expand: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isListEditing() {
      const { currentListState } = this;
      return currentListState === ListState.EDIT;
    },
    isDeleteEditing() {
      const { currentListState } = this;
      return currentListState === ListState.DELETE;
    },
    isEditing() {
      const { isListEditing, isDeleteEditing } = this;
      return isListEditing || isDeleteEditing;
    },
    listItems() {
      const { novelId, convertTileModel, selectedItems } = this;
      const attributes = this.$store.getters["materialModule/attributesFromNovelId"](novelId) as MaterialAttribute[];
      const sortOrder = this.$store.getters["materialModule/attributeSortOrder"](novelId) as SortOrder | undefined;
      let data = attributes;
      if (sortOrder) {
        data = reOrderData(attributes, sortOrder.order);
      }
      const items: MaterialTileModel[] = convertTileModel(data);
      return items.filter((item) => !selectedItems.some((remove) => remove.id === item.id));
    },
    themeItem() {
      return {
        id: "",
        text: "資料テンプレート",
        icon: templateIcon,
        link: "templates",
      };
    },
    showUpdateButton() {
      const { isListEditing } = this;
      return isListEditing;
    },
    showDeleteButton() {
      const { isDeleteEditing, selectedItems } = this;
      return isDeleteEditing && selectedItems.length > 0;
    },
  },
  methods: {
    // init

    async initialize() {
      const { $route, $store } = this;
      const { params } = $route;
      const { dispatch } = $store;
      await dispatch("materialModule/initialize", params.novelId);
      this.selectedItems = [];
      this.reorderableListItems = this.listItems;
      this.currentListState = ListState.COMPLETE;
    },

    // value

    isActive(item: MaterialTileModel) {
      const { id, link } = item;
      if (defaultMaterials.includes(link)) {
        return this.currentName === link;
      }

      const { name, params } = this.$route;
      return name === link && params.materialAttributeId === id;
    },

    // util

    showDeleteCheckbox(item) {
      const { isDeleteEditing } = this;
      const { link } = item;

      return isDeleteEditing && link === "materials";
    },
    showTextInput(item) {
      const { isListEditing } = this;
      const { link } = item;

      return isListEditing && link === "materials";
    },
    showErrorDialog(message: string) {
      const confirmDialog = new Dialog(DialogVue);
      const options = {
        title: "エラー",
        content: message,
      };

      confirmDialog.show(options);
    },
    convertTileModel(items: MaterialAttribute[]) {
      const tiles = items.map((item) => {
        const tile = {
          id: item.id,
          text: item.name,
          icon: materialIcon,
          link: "materials",
        };

        if (item.id === "character") {
          return {
            ...tile,
            icon: characterIcon,
            link: "characters",
          };
        }

        if (item.id === "worldview") {
          return {
            ...tile,
            icon: worldViewIcon,
            link: "worldViews",
          };
        }

        if (item.id === "correlation") {
          return {
            ...tile,
            icon: correlationIcon,
            link: "correlations",
          };
        }

        return tile;
      });

      return tiles;
    },
    pushPage(item) {
      const { novelId } = this;
      const { isActive } = this;

      if (isActive(item)) return;

      if (defaultMaterials.includes(item.link)) {
        this.$router.push({ name: item.link, params: { novelId } });
        return;
      }

      this.$router.push({
        name: item.link,
        params: { novelId, materialAttributeId: item.id },
      });
    },

    // event handle

    onInput(event: InputEvent) {
      if (!(event.target instanceof HTMLInputElement)) {
        return;
      }

      if (!event.target) {
        return;
      }

      const { reorderableListItems } = this;
      const { value, id } = event.target;

      this.reorderableListItems = reorderableListItems.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            text: value,
          };
        }

        return item;
      });
    },
    onClick() {
      this.isOpen = !this.isOpen;
      const { initialize } = this;
      initialize();
    },
    onLinkClick(item?: MaterialTileModel) {
      const { isEditing, isCurrent, pushPage, listItems } = this;

      if (isEditing) return;

      if (!item && !isCurrent) {
        const firstItem = listItems[0];
        pushPage(firstItem);
        return;
      }

      if (!item) {
        return;
      }

      pushPage(item);
    },
    async onAddButtonClick() {
      const { $store, $router } = this;
      const isBiiling = await isBilling($store);
      if (!isBiiling) {
        $router.push({ name: "subscriptionAnnounce", query: { from: "createMaterialAttribute" } });
        return;
      }

      const { addMaterialAttribute } = this;
      const addAttributeDialog = new Dialog(MaterialAttributeEditDialog);
      const data: MaterialAttributeEditDialogProps = {
        title: "新しい資料種別を作成",
        content: "新しく追加する資料種別を入力してください\n(例：アイテム、敵キャラ、必殺技、用語集など)",
        input: "",
        placeholder: "資料種別の名称を入力してください（20文字以内）",
        positiveCallback: addMaterialAttribute,
      };
      addAttributeDialog.show(data);
    },
    onEditIconClick(_: PointerEvent) {
      this.currentListState = ListState.EDIT;
    },
    onDeleteIconClick(_: PointerEvent) {
      this.currentListState = ListState.DELETE;
    },
    onCancelClick(_) {
      const { initialize } = this;
      initialize();
    },
    onReorder(items: MaterialTileModel[]) {
      this.reorderableListItems = items;
    },
    onCheck(item: MaterialTileModel) {
      const { selectedItems } = this;
      if (selectedItems.includes(item)) {
        this.selectedItems = selectedItems.filter((selectedItem) => selectedItem.id !== item.id);
        return;
      }

      selectedItems.push(item);
    },
    onUpdateButtonClick() {
      const { reorderableListItems, updateMaterialAttributes } = this;

      for (let index = 0; index < reorderableListItems.length; index += 1) {
        const item = reorderableListItems[index];
        if (item.text.length < 1) {
          const { showErrorDialog } = this;
          showErrorDialog("未入力の資料種別が存在します\n※空文字では保存できません");
          return;
        }
      }

      updateMaterialAttributes();
    },
    onDeleteButtonClick() {
      const { initialize, selectedItems, deleteMaterialAttributes } = this;
      if (selectedItems.length < 1) {
        initialize();
        return;
      }

      const confirmDialog = new Dialog(SimpleDialog);
      const data: SimpleDialogProps = {
        title: "資料種別を削除しますか？",
        content: "本当に資料種別を削除しますか？\n※元に戻すことはできません。",
        positive: "削除する",
        positiveCallback: deleteMaterialAttributes,
      };
      confirmDialog.show(data);
    },

    // update value (or execute action)

    async addMaterialAttribute(value: string) {
      const { initialize, novelId, $store } = this;
      const { dispatch } = $store;
      const payload = {
        novelId,
        name: value,
      };
      await dispatch("materialModule/createMaterialAttribute", payload);

      initialize();
    },
    async updateMaterialAttributes() {
      const { initialize, reorderableListItems, novelId, listItems, $store } = this;
      const { dispatch, getters } = $store;
      const preOrder = listItems.map((item) => item.id);
      const order = reorderableListItems.map((item) => item.id);
      if (preOrder !== order) await dispatch("materialModule/updateMaterialAttributeOrder", { novelId, order });

      let attributes = getters["materialModule/attributesFromNovelId"](novelId) as MaterialAttribute[];
      attributes = attributes.map((attribute) => {
        const item = reorderableListItems.find((item) => item.id === attribute.id);
        if (!item) {
          return attribute;
        }

        if (item.text === attribute.name) {
          return attribute;
        }

        return {
          ...attribute,
          name: item.text,
        };
      });
      await dispatch("materialModule/updateMaterialAttribute", attributes);

      initialize();
    },
    async deleteMaterialAttributes() {
      const { initialize, selectedItems, $store, $route } = this;
      const { dispatch } = $store;
      const deleteIds = selectedItems.map((item) => item.id);

      await dispatch("materialModule/deleteMaterialAttribute", deleteIds);
      initialize();

      const { reorderableListItems, pushPage } = this;
      const { name, params } = $route;
      if (name && deleteIds.includes(params.materialAttributeId)) {
        const first = reorderableListItems[0];
        pushPage(first);
      }
    },
    checkedItems(item) {
      const { selectedItems } = this;
      return selectedItems.includes(item);
    },
    onMouseOver() {
      this.isMouseHover = true;
    },
    onMouseLeave() {
      this.isMouseHover = false;
    },
  },
  watch: {
    $route(to: Route, from: Route) {
      if (to.name) {
        this.isCurrent = ["materials", "characters", "worldViews", "correlations"].includes(to.name);
        this.currentName = to.name;
        return;
      }

      this.isCurrent = false;
      this.currentName = "";
      this.isEditing = false;
      this.selectedItems = [];
    },
    listItems(to, from) {},
  },
});
