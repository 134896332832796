
import Vue, { PropType } from "vue";
import ImageView from "@/components/atoms/ImageView.vue";
import { Character } from "@/lib/models";
import { getCharacterKey } from "@/lib/s3";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ImageView },
  props: {
    novelId: String,
    character: Object as PropType<Character>,
  },
  computed: {
    name() {
      return this.character.name;
    },
    role() {
      const role = this.character.detail.find((detail) => detail.name === "role");
      return role ? role.value : "-";
    },
    image() {
      return getCharacterKey(this.novelId, this.character);
    },
  },
});

interface Props {
  novelId: string;
  character: Character;
}

interface Data {}

interface Computed {
  name: string;
  role: string;
  image: any;
}

interface Methods {}
