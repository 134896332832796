
import Vue from "vue";

export default Vue.extend<{}, {}, {}, Props>({
  props: {
    isUnread: {
      type: Boolean,
      default: true,
    },
  },
});

interface Props {
  isUnread: boolean;
}
