
import Vue, { PropType } from "vue";
import { format, differenceInDays } from "date-fns";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    contest: Object as PropType<Contest>,
  },
  computed: {
    id() {
      const { contest } = this;
      return contest.id;
    },
    title() {
      const { contest } = this;
      return contest.contest;
    },
    free() {
      const { contest } = this;
      return contest.free;
    },
    deadline() {
      const { contest } = this;
      const { deadline } = contest;

      if (!deadline) return "-";

      return format(deadline, "YYYY/MM/DD");
    },
    remainingDays() {
      const { deadline } = this;

      if (!deadline) return "-";

      const currentDate = format(new Date(), "YYYY/MM/DD");

      return differenceInDays(deadline, currentDate);
    },
    award() {
      const { contest } = this;
      return contest.award;
    },
    content() {
      const { contest } = this;
      return contest.content;
    },
    homepage() {
      const { contest } = this;
      return contest.homepage;
    },
    isDeadlineApproaching() {
      const { remainingDays } = this;
      return typeof remainingDays === "number" && remainingDays <= 7;
    },
  },
  methods: {
    onClickRow() {
      const { $router, id } = this;
      $router.push({ name: "eventContestDetail", params: { id } });
    },
  },
});

interface Props {
  contest: Contest;
}

interface Data {}

interface Computed {
  id: string;
  title: string;
  free: string;
  deadline: string;
  remainingDays: number | string;
  award: string;
  content: string;
  homepage: string;
  isDeadlineApproaching: boolean;
}

interface Methods {
  onClickRow(): void;
}

type Contest = {
  [key: string]: any;
};
