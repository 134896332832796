
import Vue from "vue";
import ImageViewRound from "@/components/atoms/ImageView.vue";
import { ISelectBoxItem, ImageCropType } from "@/components/molecules/SelectBox.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ImageViewRound },
  props: {
    item: [Object, String],
    label: String,
    icon: String,
    iconPlaceholder: String,
    disableHover: Boolean,
    filterMode: Boolean,
    grayColor: Boolean,
    disabled: Boolean,
  },
  computed: {
    isRectangle() {
      const { item } = this;

      if (typeof item !== "object") return false;

      const { imageCropType } = item;
      return imageCropType === ImageCropType.rectangle;
    },
  },
  methods: {
    select() {
      if (this.disabled) return;
      this.$emit("select", this.item);
    },
  },
});

interface Props {
  item: ISelectBoxItem | string;
  label: string;
  icon: string;
  iconPlaceholder: string;
  disableHover: boolean;
  filterMode: boolean;
  grayColor: boolean;
  disabled: boolean;
}

interface Data {}

interface Computed {
  isRectangle: boolean;
}

interface Methods {
  select(): void;
}
