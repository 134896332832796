
import Vue, { PropType } from "vue";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { createUrlWithUtmParams } from "@/lib/utils/url";
import { NolaItemId, NolaPageName } from "@/lib/utils/analytics";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    item: Object as PropType<Item>,
  },
  computed: {
    isContest() {
      const { item } = this;
      return item.dataType === "contest";
    },
    kind() {
      const { isContest } = this;
      return isContest ? "コンテスト" : "編集部の掲示板";
    },
    image() {
      const { item, isContest } = this;
      const image = isContest ? item.image.url : item.bannerImg.url;
      return `${image}?fit=crop&w=200&h=200`;
    },
    theme() {
      const { item } = this;
      return item.themeName || item.novelGenre.replaceAll(" / ", "×");
    },
    description() {
      const { item, isContest } = this;
      return isContest ? item.free : item.comment;
    },
    award() {
      const { item } = this;
      return item.award;
    },
    deadline() {
      const { item } = this;
      return format(item.deadline, "YYYY/MM/DD（dd） HH:mm", { locale: ja });
    },
    owner() {
      const { item, isContest } = this;
      return isContest ? item.organizer : item.name;
    },
    color() {
      const { item } = this;
      return item.color;
    },
  },
  methods: {
    onClickPrimary() {
      const { item, isContest } = this;
      if (isContest) this.$router.push({ name: "eventContestDetail", params: { id: item.id } });
      else
        window.open(
          createUrlWithUtmParams(
            `${process.env.VUE_APP_NOLANOVEL_WEB}/company/${item.id}`,
            NolaPageName.EventTheme,
            NolaItemId.CompanyCardViewDetailsButton
          ),
          process.env.VUE_APP_NOLANOVEL_NAME
        );
    },
    onClickSecondary() {
      this.$router.push({ name: "novelCreate" });
    },
  },
});

interface Props {
  item: Item;
}

interface Data {}

interface Computed {
  isContest: boolean;
  kind: string;
  image: string;
  theme: string;
  description: string;
  award: string;
  deadline: string;
  owner: string;
  color: string;
}

interface Methods {
  onClickPrimary(): void;
  onClickSecondary(): void;
}

interface Item {
  [key: string]: any;
}
