
import Vue, { PropType } from "vue";
import { format } from "date-fns";
import ChevronRightIcon from "icons/ChevronRight.vue";
import OpenInNewIcon from "icons/OpenInNew.vue";
import NolaNovelListItem from "@/components/molecules/NolaNovelListItem.vue";
import NolaNovelIntroduction from "@/components/ui/nolaNovel/NolaNovelIntroduction.vue";
import { ConnectedOtherServices, Novel } from "@/lib/models";
import { Dialog } from "@/lib/utils";
import { NolaNovelUrlGenerator } from "@/lib/utils/generator/nolanovelUrl";
import { createUrlWithUtmParams } from "@/lib/utils/url";
import { NolaItemId, NolaPageName } from "@/lib/utils/analytics";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ChevronRightIcon, OpenInNewIcon, NolaNovelListItem },
  props: {
    novel: Object as PropType<Novel>,
  },
  computed: {
    title() {
      return this.novel.title;
    },
    updatedAt() {
      const { updatedAtLatestDataInNovel, updatedAt } = this.novel;
      return format(updatedAtLatestDataInNovel || updatedAt!, "YYYY/MM/DD");
    },
    createdAt() {
      const { createdAt } = this.novel;
      return format(createdAt!, "YYYY/MM/DD");
    },
    isConnectedNolaNovel() {
      const connectedOtherServices = this.$store.getters["userModule/connectedOtherServices"] as ConnectedOtherServices;
      return connectedOtherServices.nolaNovel.result;
    },
  },
  methods: {
    async onClickPublish() {
      if (!this.isConnectedNolaNovel) {
        const introduction = new Dialog(NolaNovelIntroduction);
        introduction.show();
        return;
      }

      const url = process.env.VUE_APP_NOLANOVEL_WEB;

      if (!url) {
        throw new Error("NolaノベルのWebサイトURLが環境変数に含まれていません。");
      }

      const { novel } = this;

      const service = new NolaNovelUrlGenerator(process.env.VUE_APP_NOLANOVEL_WEB!);
      const postNovelUrl = await service.genPostNovelUrl(novel.novelId);
      window.open(
        createUrlWithUtmParams(postNovelUrl, NolaPageName.NolaNovel, NolaItemId.PublishToReadersButton),
        process.env.VUE_APP_NOLANOVEL_NAME
      );
    },
    onClickTransNovel() {
      this.$router.push({ name: "editor", params: { novelId: this.novel.novelId } });
    },
  },
});

interface Props {
  novel: Novel;
}

interface Data {}

interface Computed {
  title: string;
  updatedAt: string;
  createdAt: string;
  isConnectedNolaNovel?: boolean;
}

interface Methods {
  onClickPublish(): void;
  onClickTransNovel(): void;
}
