import { render, staticRenderFns } from "./NovelCreateForm.vue?vue&type=template&id=43cb57c6&scoped=true"
import script from "./NovelCreateForm.vue?vue&type=script&lang=ts"
export * from "./NovelCreateForm.vue?vue&type=script&lang=ts"
import style0 from "./NovelCreateForm.vue?vue&type=style&index=0&id=43cb57c6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43cb57c6",
  null
  
)

export default component.exports