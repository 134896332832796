import Vue from "vue"
import Platform from "platform";

export function checkDeprecatedBrowser() {
  if (
    Platform.name !== "Chrome" &&
    Platform.name !== "Safari" &&
    Platform.name !== "Microsoft Edge" &&
    Platform.name !== "Chrome Mobile"
  ) {
    Vue.notify({
      group: "deprecated",
      title:
        "このブラウザは Nola の推奨ブラウザではありません。安心してご利用いただくため、 Google Chrome または Safari でのご利用をお願いいたします。",
      type: "error",
      duration: -1,
    });
  }
}