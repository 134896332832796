
import Vue from "vue";
import CheckboxIcon from "@/components/atoms/CheckboxIcon.vue";

type TCopyItems = {
  label: string;
  value: string;
  checked: boolean;
  required: boolean;
};

export default Vue.extend({
  components: { CheckboxIcon },
  props: {
    novelId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [
        { label: "テーマ", value: "theme", checked: false, required: false },
        { label: "プロット", value: "plot", checked: false, required: false },
        { label: "時系列 (※)", value: "timeline", checked: false, required: false },
        { label: "原稿", value: "manuscript", checked: false, required: false },
        { label: "登場人物", value: "character", checked: false, required: false },
        { label: "世界観", value: "worldview", checked: false, required: false },
        { label: "相関関係 (※)", value: "correlation", checked: false, required: false },
        { label: "資料（カスタム作成）", value: "material", checked: false, required: false },
        { label: "メモ", value: "memo", checked: false, required: false },
      ],
      checkBoxColor: "#409eff",
      disableCheckBoxColor: "#A2A2A2",
    };
  },
  computed: {
    isButtonDisabled() {
      return this.items.every((item: TCopyItems) => !item.checked);
    },
    allItemsChecked() {
      return this.items.every((item: TCopyItems) => item.checked);
    },
  },
  methods: {
    toggleSelectAll() {
      const newItems = this.items.map((item: TCopyItems) => ({
        ...item,
        checked: !this.allItemsChecked,
      }));
      this.items = newItems;
      this.$nextTick(() => {
        this.onCheckboxChange();
      });
    },
    handleCheckboxChange(item: TCopyItems, value: boolean) {
      // eslint-disable-next-line no-param-reassign
      item.checked = value;
      this.onCheckboxChange();
    },
    onCheckboxChange() {
      const characterItem = this.items.find((i: TCopyItems) => i.value === "character");
      const plotItem = this.items.find((i: TCopyItems) => i.value === "plot");
      const materialItem = this.items.find((i: TCopyItems) => i.value === "material");
      const timelineItem = this.items.find((i: TCopyItems) => i.value === "timeline");
      const correlationItem = this.items.find((i: TCopyItems) => i.value === "correlation");

      // 相関関係と時系列どちらもチェックが入っていない場合のみ登場人物のチェックは自由選択可能
      if (correlationItem && timelineItem && characterItem) {
        if (!correlationItem.checked && !timelineItem.checked) {
          characterItem.required = false;
        } else {
          characterItem.checked = true;
          characterItem.required = true;
        }
      }

      // 時系列にチェックが入っていない場合のみ、相関関係・プロット・資料のチェックは自由選択可能
      if (timelineItem && plotItem && materialItem) {
        if (!timelineItem.checked) {
          correlationItem.required = false;
          plotItem.required = false;
          materialItem.required = false;
        } else {
          correlationItem.checked = true;
          correlationItem.required = true;
          plotItem.checked = true;
          plotItem.required = true;
          materialItem.checked = true;
          materialItem.required = true;
        }
      }
    },
    onNegativeClick() {
      this.$close(false);
    },
    onPositiveClick() {
      const copySelectedData = this.allItemsChecked
        ? []
        : this.items.filter((item: TCopyItems) => item.checked).map((item: TCopyItems) => item.value);
      this.$close(copySelectedData);
    },
    onClickOutSide() {
      this.$close(false);
    },
  },
});
