
import Vue from "vue";

export default Vue.extend({
  props: {
    link: String,
    novelId: String,
    label: String
  }
});
