
import Vue from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    banner: Array,
  },
  methods: {
    ga(id) {
      (this as any).$ga.event("banner", "click", id, 1);
    },
  },
});

interface Props {
  banner: object[];
}

interface Data {}

interface Computed {}

interface Methods {
  ga(id: string): void;
}
