
import Vue from "vue";

export default Vue.extend({
  props: {
    /** 画像のソース */
    src: {
      type: String,
      required: true,
    },
    to: {
      type: Object,
      required: true,
    },
  },
});
