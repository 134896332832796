
import Vue, { PropType } from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    name: {
      type: String as PropType<string>,
      required: true,
    },
    required: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: undefined,
    },
    description: {
      type: String as PropType<string>,
      required: false,
    },
    error: {
      type: String as PropType<string>,
      required: false,
    },
  },
});

interface Props {
  name: string;
  required?: boolean;
  description?: string;
  error?: string;
}

interface Data {}

interface Computed {}

interface Methods {}
