
import Vue from "vue";
import TextField from "@/components/molecules/TextField.vue";
import { Auth, API } from "aws-amplify";
import { showNotifyDialog } from "@/lib/dialog";
import { NolaApiInitializer } from "@/initializer";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { TextField },
  data() {
    return {
      oldMailAddress: "",
      newMailAddress: "",
      message: "",
      processing: false,
    };
  },
  async created() {
    const cognitoUser = await Auth.currentUserPoolUser();
    this.oldMailAddress = cognitoUser.username;
  },
  methods: {
    async submit() {
      this.processing = true;

      const { oldMailAddress, newMailAddress } = this;
      let userId;
      let cognitoId;

      // verify email
      // eslint-disable-next-line no-useless-escape
      if (!newMailAddress.match(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/)) {
        this.message = "メールアドレスの形式が正しくありません。";
        return;
      }

      try {
        const cognitoUser = await Auth.currentUserPoolUser();
        if (cognitoUser) {
          userId = cognitoUser.attributes.sub;
        }
        const cognitoIdentityCredentials = (await Auth.currentCredentials()) as any;
        if (cognitoIdentityCredentials) {
          cognitoId = cognitoIdentityCredentials.data.IdentityId;
        }

        NolaApiInitializer.init();

        await API.post("Rest", "/mail/verificationlink", {
          body: {
            userId,
            cognitoId,
            newMailAddress,
            oldMailAddress,
          },
        });

        this.message = "";
        await showNotifyDialog({
          content: "新しいメールアドレスに本人確認のメールを送りました。",
        });
        this.$router.push({ name: "myPage" });
      } catch (error: any) {
        console.log({ error });
        this.message = error.response.data.handledMessage || error;
      } finally {
        this.processing = false;
      }
    },
  },
});
